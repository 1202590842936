import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {some} from "lodash";
import {STAGE_TRANSLATION_MAP} from "data/constants";

interface IProps {
	gridId: number;
}

export interface IPredictorPlayerController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get showReviewGridButton(): boolean;
	get showBackButton(): boolean;
	get showButtons(): boolean;
	get phase(): string;

	openReviewGridModal: () => void;
}

@injectable()
export class PredictorPlayerController implements IPredictorPlayerController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get arePredictionsComplete() {
		if (!this._roundsStore.selectedRoundId) {
			return false;
		}
		return Boolean(
			this._predictionsStore.isAllCountryPredictionsMade(this._roundsStore.selectedRoundId) &&
				this._predictionsStore.activePredictions.slot7
		);
	}

	get showReviewGridButton() {
		if (!this._roundsStore.selectedRoundId) {
			return false;
		}
		if (this._predictionsStore.hasSavedPrediction(this._roundsStore.selectedRoundId)) {
			return Boolean(
				this._predictionsStore.hasPredictionChanged(this._roundsStore.selectedRoundId) &&
					this.arePredictionsComplete
			);
		}
		return this.arePredictionsComplete;
	}

	get showBackButton() {
		if (!this._roundsStore.selectedRoundId) {
			return false;
		}
		if (this._predictionsStore.hasSavedPrediction(this._roundsStore.selectedRoundId)) {
			return true;
		}
		return !this.arePredictionsComplete;
	}

	get showButtons() {
		return some([this.showReviewGridButton, this.showBackButton]);
	}

	get phase() {
		if (!this._roundsStore.selectedRound) {
			return "";
		}
		const translation = STAGE_TRANSLATION_MAP.get(this._roundsStore.selectedRound.stage) ?? "";
		return this.i18n.t(translation);
	}

	@action openReviewGridModal = () => {
		this._modalsStore.showModal(ModalType.REVIEW_GRID);
	};

	@action init(param: IProps): void {
		this._roundsStore.selectedRoundId = param.gridId;
	}

	@action onChange(param: IProps): void {
		this._roundsStore.selectedRoundId = param.gridId;
	}
}
