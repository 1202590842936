import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IModalLoginController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	close: () => void;
	savePredictions: () => void;
}

@injectable()
export class ModalLoginController implements IModalLoginController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.LOGIN;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	@action savePredictions = () => {
		if (!this._roundsStore.selectedRoundId) {
			return;
		}
		const predictions = this._predictionsStore.getPredictionsByGridID(
			this._roundsStore.selectedRoundId
		);
		localStorage.setItem("predictions", JSON.stringify(predictions));
	};
}
