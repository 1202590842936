/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions, css} from "@mui/material/styles";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";

export const componentsTheme: ThemeOptions = {
	components: {
		MuiCard: {
			styleOverrides: {
				root: css`
					border-radius: 22px;
					background: var(--masterbrand-dark, #19000a);
					color: #fff;
				`,
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: css`
					padding: 12px;

					&:last-child {
						padding-bottom: 12px;
					}
				`,
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: css`
					border-radius: 22px;
					background: var(--masterbrand-light, #e9f5ed);

					&.tutorial {
						background: var(--masterbrand-dark);
						color: var(--masterbrand-light, #e9f5ed);
					}
				`,
				paperWidthSm: css`
					width: 400px;
				`,
			},
		},

		MuiTable: {
			styleOverrides: {
				root: css`
					background: var(--masterbrand-dark, #19000a);
					color: var(--masterbrand-light, #e9f5ed);
				`,
			},
		},
		MuiTableBody: {
			styleOverrides: {
				root: css`
					font-weight: bold;
				`,
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: css`
					&.Mui-selected {
						border-bottom: 1px solid var(--masterbrand-dark, #19000a);
						td {
							color: var(--masterbrand-dark, #19000a);
						}

						&:hover {
							border-bottom: 1px solid var(--masterbrand-dark, #19000a);
							td {
								color: var(--masterbrand-dark, #19000a);
							}
						}
					}
				`,
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: css`
					color: var(--masterbrand-light, #e9f5ed);
					padding: 8px 24px 8px 0;

					&:first-of-type {
						padding-left: 16px;
					}
					&:last-of-type {
						padding-right: 16px;
					}

					${breakpointsTheme.down("sm")} {
						padding-right: 6px;

						&:first-of-type {
							padding-left: 10px;
						}
						&:last-of-type {
							padding-right: 10px;
						}
					}
				`,

				stickyHeader: css`
					background: var(--masterbrand-dark, #19000a);
				`,
			},
			variants: [
				{
					props: {className: "rank"},
					style: css`
						width: 50px;
						padding-right: 0;
					`,
				},
				{
					props: {className: "name"},
					style: css`
						max-width: 0;
					`,
				},
				{
					props: {className: "small"},
					style: css`
						width: 110px;
						${breakpointsTheme.down("sm")} {
							width: 55px;
						}
					`,
				},
			],
		},
		MuiSkeleton: {
			styleOverrides: {
				root: {
					backgroundColor: "var(--masterbrand-light, #e9f5ed)",
				},
			},
		},

		MuiTabs: {
			styleOverrides: {
				root: css`
					min-height: 30px;
				`,
			},
		},
		MuiTab: {
			styleOverrides: {
				root: css`
					color: var(--masterbrand-light);
					background: var(--masterbrand-dark);
					text-transform: uppercase;
					font-size: 14px;
					line-height: 1;
					padding: 12px;
					min-height: 30px;
					&.Mui-selected {
						background: var(--masterbrand-light);
						color: var(--masterbrand-dark);
					}

					&:first-of-type {
						border-radius: 22px 0 0 0;
					}

					&:last-of-type {
						border-radius: 0 22px 0 0;
					}
				`,
			},
		},
	},
};
