import React, {Fragment} from "react";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {ModalInfo} from "views/components/modals/modal_info/modal_info.component";
import {ModalReviewGrid} from "views/components/modals/modal_review_grid/modal_review_grid.component";
import {ModalRegister} from "views/components/modals/modal_register/modal_register.component";
import {TutorialModal} from "views/components/modals/tutorial_modal/tutorial_modal.component";
import {ModalShareGrid} from "./modal_share_grid/modal_share_grid.component";
import {ModalDesktopShare} from "./modal_desktop_share/modal_desktop_share.component";
import {ModalLogin} from "./modal_login/modal_login.component";

export const ModalsList = () => {
	return (
		<Fragment>
			<ModalError />
			<ModalConfirm />
			<ModalInfo />
			<ModalLogin />
			<ModalRegister />
			<ModalReviewGrid />
			<TutorialModal />
			<ModalShareGrid />
			<ModalDesktopShare />
		</Fragment>
	);
};
