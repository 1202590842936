import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IPredictorHomeController extends ViewController {
	readonly i18n: ILocalizationStore;
	get grid(): IRound | undefined;
	get grids(): IRound[];
	get hasMultipleRounds(): boolean;
}

@injectable()
export class PredictorHomeController implements IPredictorHomeController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get grid() {
		return this._roundsStore.selectedRound;
	}

	get grids() {
		return this._roundsStore.currentEventRounds;
	}

	get hasMultipleRounds() {
		return this._roundsStore.currentEventRounds.length > 1;
	}
}
