import React from "react";
import {observer} from "mobx-react";
import {Typography, Dialog, DialogContent, Stack, IconButton} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	top: 6px;
	right: 6px;
`;

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	return (
		<Dialog open={isOpen} onClose={close} maxWidth={"sm"}>
			<DialogContent>
				<Stack alignItems={"center"}>
					<CloseBlock>
						<IconButton
							sx={{minWidth: 0}}
							onClick={close}
							color={"info"}
							size={"small"}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>
					<Icon>
						<ErrorIcon />
					</Icon>
					<Typography>
						{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
					</Typography>
					<Exist when={modalContent?.message}>
						<Typography>{modalContent?.message}</Typography>
					</Exist>
				</Stack>
			</DialogContent>
		</Dialog>
	);
});
