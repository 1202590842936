import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {
	IRankingsStore,
	IEventRankRow,
	IEventRankings,
	IRankings,
	IRankRow,
	IFilters,
} from "data/stores/rankings/rankings.store";
import {ModalType, RequestState, Stage} from "data/enums";
import type {IEvent, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import {SelectChangeEvent} from "@mui/material";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IRankingsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;

	get isLoadingMore(): boolean;

	get rankingsList(): IRankRow[] | IEventRankRow[];

	get standingUser(): IRankRow | IEventRankRow | null;

	get hasMore(): boolean;

	get gridUserID(): number | null;

	get events(): IEvent[];

	get filters(): IFilters;

	get selectedEvent(): string;

	get showPoolPoints(): boolean;

	get showCupPoints(): boolean;

	get isOverallStandings(): boolean;

	requestMore: () => void;

	setGridUserID: (value: number) => void;

	onChangeFilters: (e: SelectChangeEvent<unknown>) => void;
}

@injectable()
export class RankingsController implements IRankingsController {
	@observable private _fetchRankingsDisposer?: ReturnType<typeof reaction>;
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _requestStateMore: RequestState = RequestState.IDLE;

	@observable _gridViewUsedID: number | null = null;

	private _page = 1;
	private _limit = 20;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RankingsStore) readonly _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._requestState !== RequestState.SUCCESS;
	}

	get isLoadingMore(): boolean {
		return this._requestStateMore === RequestState.PENDING;
	}

	get rankings(): IRankings | IEventRankings {
		if (this.filters.event !== "overall") {
			return this._rankingsStore.eventLeaderboard;
		}
		return this._rankingsStore.leaderboard;
	}

	get rankingsList(): IRankings["rankings"] | IEventRankings["rankings"] {
		return this.rankings.rankings;
	}

	get standingUser(): IRankRow | IEventRankRow | null {
		return this.rankings.user ?? null;
	}

	get hasMore(): boolean {
		return this.rankings.nextPage;
	}

	get gridUserID(): number | null {
		return this._gridViewUsedID;
	}

	get events(): IEvent[] {
		return this._roundsStore.activeOrCompleteEvents;
	}

	get filters(): IFilters {
		return this._rankingsStore.filters;
	}

	get selectedEvent(): string {
		return this.events.find((event) => event.id === +this.filters.event)?.name ?? "";
	}

	get showCupPoints(): boolean {
		return this.filters.event !== "overall" && this.filters.stage === Stage.Cup;
	}

	get showPoolPoints(): boolean {
		return this.filters.event !== "overall" && this.filters.stage === Stage.Pool;
	}

	get isOverallStandings(): boolean {
		return this.filters.event === "overall";
	}

	init() {
		this.request();
	}

	dispose() {
		this._rankingsStore.resetFilters();
		this._fetchRankingsDisposer?.();
	}

	@action setDefaultFilters = () => {
		if (this._roundsStore.scoreRound) {
			this._rankingsStore.filters = {
				event: this._roundsStore.scoreRound.event.id.toString(),
				stage: this._roundsStore.scoreRound.stage,
			};
		}
	};

	@action onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action onSuccessLoadMore = () => {
		this._requestStateMore = RequestState.SUCCESS;
	};

	@action onErrorLoadMore = (error: AxiosError<IApiResponse>) => {
		this._requestStateMore = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action requestMore = () => {
		this._page += 1;
		this._requestStateMore = RequestState.PENDING;
		if (this.filters.event === "overall") {
			this._rankingsStore
				.fetchMore({
					limit: this._limit,
					page: this._page,
				})
				.then(this.onSuccessLoadMore)
				.catch(this.onErrorLoadMore);
		} else {
			this._rankingsStore
				.fetchMoreEvent({
					eventId: +this.filters.event,
					limit: this._limit,
					page: this._page,
				})
				.then(this.onSuccessLoadMore)
				.catch(this.onErrorLoadMore);
		}
	};

	@action request() {
		this._requestState = RequestState.PENDING;

		Promise.all([this._squadsStore.fetchSquads(), this._playersStore.fetchPlayers()])
			.then(this.setDefaultFilters)
			.catch(this.onError);

		this._fetchRankingsDisposer = reaction(
			() => this.filters.event,
			() => {
				this._requestState = RequestState.PENDING;

				this._page = 1;

				this._gridViewUsedID = null;

				if (this.filters.event === "overall") {
					this._rankingsStore
						.fetch({
							limit: this._limit,
							page: this._page,
						})
						.then(this.onSuccess)
						.catch(this.onError);
				} else {
					this._rankingsStore
						.fetchEvent({
							eventId: +this.filters.event,
							limit: this._limit,
							page: this._page,
						})
						.then(this.onSuccess)
						.catch(this.onError);
				}
			},
			{fireImmediately: true}
		);
	}

	@action setGridUserID = (value: number | null) => {
		if (value === this._gridViewUsedID) {
			this._gridViewUsedID = null;
			return;
		}
		this._gridViewUsedID = value;
	};

	@action onChangeFilters = (e: SelectChangeEvent<unknown>) => {
		const {value, name} = e.target;
		this._rankingsStore.filters = {
			...this.filters,
			[name]: value,
		};
	};
}
