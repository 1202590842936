import {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {
	IPoints,
	IPredictions,
	IPredictionsPayload,
	ISummary,
} from "data/stores/predictions/predictions.store";
import {inject, injectable} from "inversify";

interface IGridPayload {
	gridId: number;
}

interface IEventPayload {
	eventId: number;
}

interface IOtherPredictionsPayload {
	eventId: number;
	userId: number;
}

type IGridResponse = IApiResponse<{prediction: IPredictions}>;
type IEventResponse = IApiResponse<{predictions: IPredictions[]; points: IPoints[]}>;
type ISummaryResponse = IApiResponse<ISummary>;

export interface IPredictionsApiProvider {
	grid: (params: IGridPayload) => Promise<AxiosResponse<IGridResponse>>;
	event: (params: IEventPayload) => Promise<AxiosResponse<IEventResponse>>;
	save: (params: IPredictionsPayload) => Promise<AxiosResponse<IGridResponse>>;
	autofill: (params: IPredictionsPayload) => Promise<AxiosResponse<IGridResponse>>;
	otherPredictions: (params: IOtherPredictionsPayload) => Promise<AxiosResponse<IEventResponse>>;
	summary: () => Promise<AxiosResponse<ISummaryResponse>>;
}

@injectable()
export class PredictionsApiProvider implements IPredictionsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	grid = ({gridId}: IGridPayload) =>
		this._http.get<IGridResponse>(`grid_predictor/predictions/grid/${gridId}`);

	event = ({eventId}: IEventPayload) =>
		this._http.get<IEventResponse>(`grid_predictor/predictions/event/${eventId}`);

	save = (params: IPredictionsPayload) =>
		this._http.post<IGridResponse>("grid_predictor/predictions/save", params);

	autofill = (params: IPredictionsPayload) =>
		this._http.post<IGridResponse>("grid_predictor/predictions/autofill", params);

	otherPredictions = ({userId, eventId}: IOtherPredictionsPayload) =>
		this._http.get<IEventResponse>(
			`grid_predictor/other_predictions/event/${userId}/${eventId}`
		);

	summary = () => this._http.get<ISummaryResponse>("grid_predictor/summary");
}
