import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {ModalType, RequestState} from "data/enums";
import {get, isEqual} from "lodash";
import {IAxiosApiErrorGeneral} from "data/types/error";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {EVENT_THEMES, GLOBAL_THEME, IEventTheme} from "data/constants/events";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import {useLocation} from "react-router-dom";

interface IParam {
	location: ReturnType<typeof useLocation>;
}

export interface IEventThemeController extends ViewController<IParam> {
	get isLoading(): boolean;
	get eventTheme(): IEventTheme;
}

@injectable()
export class EventThemeController implements IEventThemeController {
	@observable private _location?: ReturnType<typeof useLocation>;
	@observable private _requestState = RequestState.PENDING;

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get eventTheme() {
		if (!this._roundsStore.selectedRound) {
			return GLOBAL_THEME;
		}

		if (
			this._location?.pathname.includes("rankings") &&
			this._rankingsStore.filters.event === "overall"
		) {
			return GLOBAL_THEME;
		}

		const currentEvent = this._roundsStore.selectedRound.event;
		const filterEvent = this._roundsStore.events.find(
			({id}) => id === +this._rankingsStore.filters.event
		);

		const event = filterEvent ? filterEvent.abbreviation : currentEvent.abbreviation;
		return get(EVENT_THEMES, event, GLOBAL_THEME);
	}

	@action onChange(param: IParam) {
		this._location = param.location;
	}

	@action init(param: IParam): void {
		this._location = param.location;
		this._roundsStore.fetchRounds().then(this.onSuccess).catch(this.onError);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (err: IAxiosApiErrorGeneral) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};
}
