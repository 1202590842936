import React from "react";
import styled from "@emotion/styled";
import {Stack, Box, Typography, useMediaQuery, Container} from "@mui/material";
import {NavLink} from "react-router-dom";
import {ReactComponent as LogoSVNS} from "assets/img/logos/svns.svg";
import {useViewController} from "data/services/locator";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import {FooterNavigation} from "views/components/footer/footer_navigation/footer_navigation.component";
import {Socials} from "views/components/footer/socials/socials.component";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";
import {Exist} from "views/components/exist/exist.component";

const Wrapper = styled(Stack)`
	border-top: 2px solid var(--masterbrand-light, #e9f5ed);
	background: var(--masterbrand-dark, #19000a);
	color: var(--masterbrand-light, #e9f5ed);
	justify-content: center;
	gap: 16px;
	align-items: center;
	position: relative;
	&:before {
		position: absolute;
		top: 24px;
		left: 0;
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		background: var(--masterbrand-light, #e9f5ed);
	}

	${breakpointsTheme.up("md")} {
		gap: 32px;
	}
`;

const Content = styled(Container)`
	display: flex;
	flex-flow: column;
	justify-content: center;
	gap: 16px;
	align-items: center;
	padding: 40px 28px 24px;
	${breakpointsTheme.up("md")} {
		flex-flow: row;
		width: 100%;
		padding-bottom: 0;

		& > a {
			margin-right: auto;
		}
	}
`;

const GameLogo = styled(Box)`
	width: 240px;
	margin: 0 auto;

	svg {
		height: auto;
		width: 100%;
	}

	${breakpointsTheme.up("md")} {
		width: 360px;
	}
`;

const BottomWrapper = styled(Box)`
	border-top: 1px solid var(--masterbrand-light, #e9f5ed);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 16px;
	padding-top: 7px;
`;

export const Footer: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const isMobile = useMediaQuery(`(max-width: ${breakpointsTheme.values.md}px)`);

	return (
		<Wrapper>
			<Content>
				<NavLink to={"/"}>
					<GameLogo>
						<LogoSVNS />
					</GameLogo>
				</NavLink>

				<Socials />

				<Exist when={isMobile}>
					<FooterNavigation />
					<Typography variant={"caption"}>
						{i18n.t(
							"footer.nav.copyrights",
							"  © 2023 HSBC SVNS ALL COPYRIGHTS RESERVED"
						)}
					</Typography>
				</Exist>
			</Content>
			<Exist when={!isMobile}>
				<BottomWrapper>
					<Container>
						<Stack direction={"row"} width={"100%"}>
							<FooterNavigation />

							<Typography variant={"caption"} sx={{whiteSpace: {md: "nowrap"}}}>
								{i18n.t(
									"footer.nav.copyrights",
									"  © 2023 HSBC SVNS ALL COPYRIGHTS RESERVED"
								)}
							</Typography>
						</Stack>
					</Container>
				</BottomWrapper>
			</Exist>
		</Wrapper>
	);
});
