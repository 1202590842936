import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {ModalType, RoundStatus} from "data/enums";

interface IParam {
	onCancel: () => void;
}

export interface IModalPromptInnerController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isNewPredictions(): boolean;
	get message(): string;
	onReviewGrid: () => void;
}

@injectable()
export class ModalPromptInnerController implements IModalPromptInnerController {
	@observable private _onCancel: (() => void) | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	get isNewPredictions() {
		const selectedRound = this._roundsStore.selectedRound;
		if (selectedRound && selectedRound.status === RoundStatus.Scheduled) {
			return !this._predictionsStore.hasSavedPrediction(selectedRound.id);
		}
		const predictionRound = this._predictionsStore.firstChangedPrediction;
		if (predictionRound) {
			return !this._predictionsStore.hasSavedPrediction(predictionRound);
		}
		return true;
	}

	get message() {
		if (this.isNewPredictions) {
			return this.i18n.t(
				"leave.modal.make_copy",
				"IF YOU LEAVE NOW YOU'LL LOSE YOUR GRID PICKS"
			);
		}
		return this.i18n.t(
			"leave.modal.edit_copy",
			"IT LOOKS LIKE YOU HAVEN'T SAVED YOUR LATEST GRID UPDATE IF YOU LEAVE NOW YOU'LL LOSE YOUR UPDATES. CLICK REVIEW GRID TO SAVE THESE NOW."
		);
	}

	@action onReviewGrid = () => {
		this._onCancel?.();
		this._modalsStore.showModal(ModalType.REVIEW_GRID);
	};

	init(param: IParam): void {
		this._onCancel = param.onCancel;
	}

	onChange(param: IParam): void {
		this._onCancel = param.onCancel;
	}
}
