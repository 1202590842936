import {IAxiosApiErrorGeneral, IAxiosErrorMessageObject} from "data/types/error";

export function getErrorMessageFromAxiosResponse(event: IAxiosApiErrorGeneral) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}

export const getKcToken = () => {
	try {
		return localStorage.getItem("kc_token");
	} catch (e) {
		return null;
	}
};

export function getFlagEmoji(countryCode: string) {
	const codePoints = countryCode
		.toUpperCase()
		.split("")
		.map((char) => 127397 + char.charCodeAt(0));
	return String.fromCodePoint(...codePoints);
}
