import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalLoginController} from "views/components/modals/modal_login/modal_login.controller";
import {Box, Button, Dialog, DialogContent, IconButton, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeRound.svg";
import {useKeycloak} from "@react-keycloak/web";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	top: 6px;
	right: 6px;
`;

export const ModalLogin: React.FC = observer(() => {
	const {keycloak} = useKeycloak();
	const {i18n, isOpen, close, savePredictions} = useViewController<IModalLoginController>(
		Bindings.ModalLoginController
	);

	return (
		<Dialog open={isOpen} onClose={close} maxWidth={"sm"}>
			<DialogContent>
				<Stack alignItems={"center"} sx={{paddingY: "40px"}} gap={"18px"}>
					<CloseBlock>
						<IconButton sx={{minWidth: 0}} onClick={close}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>

					<Typography variant="h3">{i18n.t("login.modal.header", "LOGIN")}</Typography>

					<Typography>
						{i18n.t("login.modal.desc", "LOGIN OR REGISTER TO SAVE YOUR PREDICTIONS")}
					</Typography>
					<Box display="flex" justifyContent="center" gap="10px">
						<Button
							variant="contained"
							color="secondary"
							component="a"
							onClick={savePredictions}
							href={keycloak.createLoginUrl({
								redirectUri: window.location.origin + "/predictor",
							})}>
							{i18n.t("login.modal.login_button", "LOGIN")}
						</Button>
						<Button
							variant="contained"
							color="primary"
							component="a"
							onClick={savePredictions}
							href={keycloak.createRegisterUrl({
								redirectUri: window.location.origin + "/predictor",
							})}>
							{i18n.t("login.modal.register_button", "REGISTER")}
						</Button>
					</Box>
				</Stack>
			</DialogContent>
		</Dialog>
	);
});
