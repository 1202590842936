import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {IAxiosApiErrorGeneral} from "data/types/error";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {useNavigate} from "react-router";
import type {IUserStore} from "data/stores/user/user.store";

interface IParam {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IModalReviewGridController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;
	get gridId(): number | null;
	get event(): string;
	close: () => void;
	onSaveGrid: () => void;
}

@injectable()
export class ModalReviewGridController implements IModalReviewGridController {
	@observable private _navigate?: ReturnType<typeof useNavigate>;

	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.REVIEW_GRID;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get gridId() {
		return this._roundsStore.selectedRoundId;
	}

	get event() {
		return this._roundsStore.selectedRound?.event.name ?? "";
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	@action onSaveGrid = () => {
		if (!this._roundsStore.selectedRoundId) {
			return;
		}
		if (!this._userStore.isAuthorized) {
			this._modalsStore.showModal(ModalType.LOGIN);
			return;
		}
		this._predictionsStore
			.savePredictions(this._roundsStore.selectedRoundId)
			.then(() => {
				this._modalsStore.hideModal();
				this._navigate?.("/predictor");

				if (this.gridId) {
					this._modalsStore.showModal(ModalType.SHARE_GRID, {
						message: "",
						gridId: this.gridId,
					});
				}
			})
			.catch(this.onError);
	};

	init(param: IParam): void {
		this._navigate = param.navigate;
	}
}
