import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {IAxiosApiErrorGeneral} from "data/types/error";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";

export interface IMyAccountController extends ViewController {
	readonly i18n: ILocalizationStore;
	get user(): IUser | undefined;
	get selectedNotifications(): boolean;
	get isLoading(): boolean;
	get isDisabled(): boolean;
	toggleSelectedEmailNotifications: () => void;
	updatePreferences: () => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _selectedNotifications: boolean = true;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) readonly _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get user() {
		return this._userStore.user;
	}

	get selectedNotifications() {
		return this._selectedNotifications;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isDisabled() {
		if (this.isLoading) {
			return true;
		}
		return this.selectedNotifications === this._userStore.user?.isNotificationsEnabled;
	}

	@action init(): void {
		this._selectedNotifications = this.user?.isNotificationsEnabled ?? false;
	}

	@action toggleSelectedEmailNotifications = () => {
		this._selectedNotifications = !this.selectedNotifications;
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (err: IAxiosApiErrorGeneral) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	@action updatePreferences = () => {
		this._requestState = RequestState.PENDING;
		this._userStore
			.update({
				isNotificationsEnabled: this.selectedNotifications,
			})
			.then(this.onSuccess)
			.catch(this.onError);
	};
}
