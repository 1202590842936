import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IEvent, IRoundsStore} from "data/stores/rounds/rounds.store";
import Swiper from "swiper";
import {useNavigate} from "react-router-dom";
import {last} from "lodash";
import {RoundStatus} from "data/enums";
import type {IPredictionsStore, ISummary} from "data/stores/predictions/predictions.store";

interface IParam {
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILandingAuthorizedController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get initialSlide(): number;
	get availableEvents(): IEvent[];
	get swiper(): Swiper | null;
	get activeIndex(): number;
	get activeEvent(): IEvent | null;
	get summary(): ISummary;

	onSwiper: (swiper: Swiper) => void;
	onPrev: () => void;
	onNext: () => void;
	onUpdateIndex: (swiper: Swiper) => void;
	onSelectEvent: () => void;
	setOptionClassName: (value: string) => string;
	updateSelectedEvent: (event: string) => void;
}

@injectable()
export class LandingAuthorizedController implements ILandingAuthorizedController {
	@observable private _swiper: Swiper | null = null;
	@observable private _activeIndex: number = 0;
	@observable private _navigate?: ReturnType<typeof useNavigate>;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) readonly _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	get initialSlide() {
		return this.availableEvents.findIndex(
			(e) => e.abbreviation === this._roundsStore.selectedRound?.event.abbreviation
		);
	}

	get availableEvents() {
		return this._roundsStore.availableEvents;
	}

	get swiper() {
		return this._swiper;
	}

	get activeIndex() {
		return this._activeIndex;
	}

	get activeEvent() {
		if (!this.swiper) {
			return null;
		}
		return this.availableEvents[this.activeIndex];
	}

	get summary() {
		return this._predictionsStore.summary;
	}

	init(param: IParam): void {
		this._navigate = param.navigate;

		void this._predictionsStore.fetchSummary();
	}

	@action onSwiper = (swiper: Swiper) => {
		this._swiper = swiper;
	};

	onPrev = () => {
		this._swiper?.slidePrev();
	};

	onNext = () => {
		this._swiper?.slideNext();
	};

	@action onUpdateIndex = (swiper: Swiper) => {
		this._activeIndex = swiper.activeIndex;
	};

	onSelectEvent = () => {
		const eventRounds = this._roundsStore.list.filter(
			(e) => e.event.abbreviation === this.activeEvent?.abbreviation
		);
		const eventRound =
			eventRounds.find(({status}) =>
				[RoundStatus.Playing, RoundStatus.Scheduled].includes(status)
			) || last(eventRounds);

		if (eventRound) {
			this._roundsStore.selectedRoundId = eventRound.id;
			this._navigate?.("/predictor");
		}
	};

	setOptionClassName = (value: string) => {
		return this.activeEvent?.abbreviation === value ? "active" : "";
	};

	@action updateSelectedEvent = (event: string) => {
		const eventIndex = this.availableEvents.findIndex((e) => e.abbreviation === event);
		this.swiper?.slideTo(eventIndex);
	};
}
