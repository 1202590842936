import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type ITutorialModalController} from "views/components/modals/tutorial_modal/tutorial_modal.controller";
import {Dialog, DialogContent, IconButton, Stack, Typography, Box, Button} from "@mui/material";
import styled from "@emotion/styled";
import {ReactComponent as CloseIcon} from "assets/img/icons/close.svg";
import logo from "assets/img/logos/svnsWhite.svg";
import {IndicatorDots} from "views/components/indicator_dots/indicator_dots.component";
import {Exist} from "views/components/exist/exist.component";
import {useTheme} from "@emotion/react";
import step7 from "assets/img/tutorial/step7.jpeg";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const StackCenter = styled(Stack)`
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export const TutorialModal: React.FC = observer(() => {
	const theme = useTheme();
	const {
		i18n,
		onPrev,
		onNext,
		onSelect,
		hideModal,
		isOpen,
		screens,
		setOptionClassName,
		currentScreen,
		showWelcomeScreen,
	} = useViewController<ITutorialModalController>(Bindings.TutorialModalController);

	return (
		<Dialog
			open={isOpen}
			onClose={hideModal}
			PaperProps={{
				className: "tutorial",
			}}>
			<DialogContent
				sx={{
					padding: "6px 6px 30px",
					textTransform: "uppercase",
				}}>
				<CloseBlock>
					<IconButton sx={{minWidth: 0}} onClick={hideModal} size={"small"}>
						<CloseIcon />
					</IconButton>
				</CloseBlock>

				<StackCenter gap={"12px"}>
					<Box
						component={"img"}
						src={logo}
						alt="SVNS"
						role={"presentation"}
						sx={{height: "80px"}}
					/>

					<Exist when={!showWelcomeScreen}>
						<Box
							height={"160px"}
							component="img"
							src={currentScreen.image}
							alt={currentScreen.title}
						/>
						<StackCenter gap={"8px"} sx={{mb: "12px"}}>
							<Typography variant={"h2"}>{currentScreen.title}</Typography>
							<Typography>{currentScreen.description}</Typography>
						</StackCenter>

						<IndicatorDots
							onPrev={onPrev}
							onNext={onNext}
							onSelect={onSelect}
							setOptionClassName={setOptionClassName}
							options={screens.map((_it, index) => index.toString())}
							variant={"contained"}
							arrowsColor={theme.palette.eventPrimary.main}
						/>
					</Exist>

					<Exist when={showWelcomeScreen}>
						<Box
							height={"160px"}
							component="img"
							src={step7}
							alt={i18n.t("tutorial.seven.header")}
							borderRadius="22px"
						/>
						<StackCenter gap={"8px"} sx={{mb: "12px"}}>
							<Typography variant={"h2"}>
								{i18n.t("tutorial.seven.header")}
							</Typography>
						</StackCenter>

						<Button color="eventPrimary" variant="contained" onClick={hideModal}>
							{i18n.t("tutorial.seven.button")}
						</Button>
					</Exist>
				</StackCenter>
			</DialogContent>
		</Dialog>
	);
});
