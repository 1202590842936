export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	BackdoorController: Symbol("BackdoorController"),
	PredictorController: Symbol("PredictorController"),
	PredictorGridController: Symbol("PredictorGridController"),
	PredictorGridButtonController: Symbol("PredictorGridButtonController"),
	DaySelectorController: Symbol("DaySelectorController"),
	PredictionsStore: Symbol("PredictionsStore"),
	LandingController: Symbol("LandingController"),
	PredictorCountryController: Symbol("PredictorCountryController"),
	PredictorHomeController: Symbol("PredictorHomeController"),
	ModalInfoController: Symbol("ModalInfoController"),
	PredictionsApiProvider: Symbol("PredictionsApiProvider"),
	RankingsController: Symbol("RankingsController"),
	RankingsProvider: Symbol("RankingsProvider"),
	RankingsStore: Symbol("RankingsStore"),
	PredictorPlayerController: Symbol("PredictorPlayerController"),
	PlayerPoolController: Symbol("PlayerPoolController"),
	ModalReviewGridController: Symbol("ModalReviewGridController"),
	PredictorGridPlayerButtonController: Symbol("PredictorGridPlayerButtonController"),
	ModalRegisterController: Symbol("ModalRegisterController"),
	PlayerPoolRowController: Symbol("PlayerPoolRowController"),
	NavigationController: Symbol("NavigationController"),
	LogoutController: Symbol("LogoutController"),
	TutorialModalController: Symbol("TutorialModalController"),
	LockoutTimerController: Symbol("LockoutTimerController"),
	TimerController: Symbol("TimerController"),
	PredictorLockoutLabelController: Symbol("PredictorLockoutLabelController"),
	ModalPromptInnerController: Symbol("ModalPromptInnerController"),
	PredictorGridOtherController: Symbol("PredictorGridOtherController"),
	EventThemeController: Symbol("EventThemeController"),
	RankingsRowController: Symbol("RankingsRowController"),
	MyAccountController: Symbol("MyAccountController"),
	LandingUnauthorizedController: Symbol("LandingUnauthorizedController"),
	LandingAuthorizedController: Symbol("LandingAuthorizedController"),
	LandingEventController: Symbol("LandingEventController"),
	LandingUpcomingEventsController: Symbol("LandingUpcomingEventsController"),
	ModalShareGridController: Symbol("ModalShareGridController"),
	ModalDesktopShareController: Symbol("ModalDesktopShareController"),
	ModalLoginController: Symbol("ModalLoginController"),
};
