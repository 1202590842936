import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {RoundStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";

interface IParams {
	status: RoundStatus;
	gridId: number;
}

export interface IPredictorLockoutLabelController extends ViewController<IParams> {
	i18n: ILocalizationStore;

	get status(): RoundStatus | null;
	get lockoutDate(): string;
	get isLocked(): boolean;
	get isComplete(): boolean;

	onTimerComplete: () => void;
}

@injectable()
export class PredictorLockoutLabelController implements IPredictorLockoutLabelController {
	@observable private _gridStatus: RoundStatus | null = null;
	@observable private _gridId: number | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	get status(): RoundStatus | null {
		return this._gridStatus;
	}

	get lockoutDate(): string {
		if (!this._gridId) {
			return "";
		}
		return this._predictionsStore.getCurrentLockoutDate(this._gridId) ?? "";
	}

	get isLocked(): boolean {
		if (!this._gridId) {
			return true;
		}
		return this._predictionsStore.isGridLocked(this._gridId);
	}

	get isComplete(): boolean {
		if (!this._gridId) {
			return false;
		}
		return this.status === RoundStatus.Complete;
	}

	init(param: IParams) {
		this.setStatus(param);
		this._gridId = param.gridId;
	}

	onChange(param: IParams) {
		this.setStatus(param);
		this._gridId = param.gridId;
	}

	private setStatus = (param: IParams) => {
		if (
			this.isDateBeforeNow(new Date(this.lockoutDate)) &&
			param.status === RoundStatus.Scheduled
		) {
			this._gridStatus = RoundStatus.Playing;
			return;
		}
		this._gridStatus = param.status;
	};

	@action onTimerComplete = () => {
		this._gridStatus = RoundStatus.Playing;
		if (this._gridId) {
			this._roundsStore.setRoundToLocked(this._gridId);
		}
	};

	private isDateBeforeNow(_date: Date) {
		// Enable it if we need to validate date;
		// return date.getTime() < new Date().getTime();
		return false;
	}
}
