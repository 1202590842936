import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IEvent, IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {GameStatus, ModalType, RequestState, Slot} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {IAxiosApiErrorGeneral} from "data/types/error";
import {isEqual} from "lodash";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import {SLOT_POINTS_MAP} from "data/constants";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";

interface IParam {
	userId: number;
}

export interface IPredictorGridOtherController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get grid(): IRound | undefined;
	get player(): IPlayer | undefined;
	get playerCountry(): ISquad | undefined;
	get isPlayerMatchComplete(): boolean;
	get event(): IEvent | undefined;
	getCountryBySlot: (slot: Slot) => ISquad | undefined;
	getPointsBySlot: (slot: Slot) => number | "-";
	isMatchComplete: (slot: Slot) => boolean;
}

@injectable()
export class PredictorGridOtherController implements IPredictorGridOtherController {
	@observable private _userId: number | null = null;
	@observable private _requestState = RequestState.PENDING;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.RankingsStore) readonly _rankingsStore: IRankingsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get grid() {
		if (isNaN(+this._rankingsStore.filters.event)) {
			return this._roundsStore.scoreRound;
		}

		const eventRounds = this._roundsStore.getRoundsByEventId(
			+this._rankingsStore.filters.event
		);
		return eventRounds.find((round) => round.stage === this._rankingsStore.filters.stage);
	}

	get predictions() {
		if (!this.grid) {
			return {};
		}
		return this._predictionsStore.getOtherPredictionsByGridID(this.grid.id);
	}

	get player() {
		if (!this.predictions.slot7) {
			return;
		}
		return this._playersStore.getPlayerById(this.predictions.slot7);
	}

	get playerCountry() {
		if (!this.player) {
			return;
		}
		return this._squadsStore.getSquadById(this.player.squadId);
	}

	get isPlayerMatchComplete() {
		if (!this.grid) {
			return false;
		}

		if (!this.playerCountry) {
			return false;
		}

		const match = this.grid.games.find(
			({awayId, homeId}) =>
				this.playerCountry && [awayId, homeId].includes(this.playerCountry.id)
		);

		if (!match) {
			return false;
		}

		return match.status === GameStatus.Complete;
	}

	get event() {
		if (!this.grid) {
			return;
		}
		return this.grid.event;
	}

	getPointsBySlot = (slot: Slot) => {
		if (!this.grid) {
			return "-";
		}
		const points = this._predictionsStore.getOtherPointsByGridID(this.grid.id);
		const index = SLOT_POINTS_MAP.get(slot);
		if (points && index) {
			return points[index] ?? 0;
		}
		return 0;
	};

	getCountryBySlot = (slot: Slot) => {
		const prediction = this.predictions[slot];
		if (prediction) {
			return this._squadsStore.getSquadById(prediction);
		}
	};

	isMatchComplete = (slot: Slot) => {
		if (!this.grid) {
			return false;
		}

		const country = this.getCountryBySlot(slot);
		const match = this.grid.games.find(
			({awayId, homeId}) => country && [awayId, homeId].includes(country.id)
		);

		if (!match) {
			return false;
		}

		return match.status === GameStatus.Complete;
	};

	@action init(param: IParam): void {
		this._userId = param.userId;

		this._requestState = RequestState.PENDING;
		if (!isNaN(+this._rankingsStore.filters.event)) {
			this._predictionsStore
				.fetchOtherPredictions(this._userId, +this._rankingsStore.filters.event)
				.then(this.onSuccess)
				.catch(this.onError);
		}
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (err: IAxiosApiErrorGeneral) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};
}
