import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {observable, makeAutoObservable} from "mobx";

export interface ISecretGateController extends ViewController {
	readonly i18n: ILocalizationStore;
	get formState(): boolean;

	handleSecretClick: () => void;
	checkPassword: (value: string) => void;

	hideForm: () => void;
}

@injectable()
export class SecretGateController implements ISecretGateController {
	private static _count = 0;

	@observable private _formState: boolean = false;
	@observable private _password: string = "Let me play!";

	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	openForm = () => {
		this._formState = true;
	};

	hideForm = () => {
		this._formState = false;
	};

	get formState() {
		return this._formState;
	}

	static get IS_SECRET_PASSED() {
		const IS_SECRET_ENABLED = Boolean(
			JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false")
		);

		if (IS_SECRET_ENABLED) {
			return Boolean(JSON.parse(sessionStorage.getItem("isSecretPassed") || "false"));
		}

		return true;
	}

	handleSecretClick = () => {
		if (SecretGateController.IS_SECRET_PASSED) {
			return;
		}

		SecretGateController._count += 1;

		if (SecretGateController._count >= 10) {
			this.openForm();
		}
	};

	checkPassword = (value: string) => {
		this.hideForm();
		SecretGateController._count = 0;
		if (this._password === value) {
			this._disableSecret();
		}
	};

	private _disableSecret() {
		sessionStorage.setItem("isSecretPassed", "true");
		window.location.reload();
	}
}
