import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType} from "data/enums";
import step1 from "assets/img/tutorial/step1.png";
import step2 from "assets/img/tutorial/step2.png";
import step3 from "assets/img/tutorial/step3.png";
import step4 from "assets/img/tutorial/step4.png";
import step5 from "assets/img/tutorial/step5.png";
import step6 from "assets/img/tutorial/step6.png";

interface IScreen {
	image: string;
	title: string;
	description: string;
}
export interface ITutorialModalController extends ViewController {
	i18n: ILocalizationStore;

	get screens(): IScreen[];
	get currentScreen(): IScreen;
	get isOpen(): boolean;
	get showWelcomeScreen(): boolean;

	hideModal: () => void;
	setOptionClassName: (option: string) => string;
	onPrev: () => void;
	onNext: () => void;
	onSelect: (option: string) => void;
}

@injectable()
export class TutorialModalController implements ITutorialModalController {
	@observable private _activeScreenIndex: number = 0;
	@observable private _showWelcomeScreen: boolean = false;
	@observable private _tutorialDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TUTORIAL;
	}

	hideModal = () => {
		this._modalsStore.hideModal();
		this._activeScreenIndex = 0;
	};

	get screens(): IScreen[] {
		return [
			{
				image: step1,
				title: this.i18n.t("tutorial.one.header"),
				description: this.i18n.t("tutorial.one.copy"),
			},
			{
				image: step2,
				title: this.i18n.t("tutorial.two.header"),
				description: this.i18n.t("tutorial.two.copy"),
			},
			{
				image: step3,
				title: this.i18n.t("tutorial.three.header"),
				description: this.i18n.t("tutorial.three.copy"),
			},
			{
				image: step4,
				title: this.i18n.t("tutorial.four.header"),
				description: this.i18n.t("tutorial.four.copy"),
			},
			{
				image: step5,
				title: this.i18n.t("tutorial.five.header"),
				description: this.i18n.t("tutorial.five.copy"),
			},
			{
				image: step6,
				title: this.i18n.t("tutorial.six.header"),
				description: this.i18n.t("tutorial.six.copy"),
			},
		];
	}

	get currentScreen(): IScreen {
		return this.screens[this._activeScreenIndex] || this.screens[0];
	}

	get showWelcomeScreen(): boolean {
		return this._showWelcomeScreen;
	}

	setOptionClassName = (value: string) => {
		return this._activeScreenIndex.toString() === value ? "active" : "";
	};

	onPrev = () => {
		const nextIndex = this._activeScreenIndex - 1;
		if (this.screens[nextIndex]) {
			this._activeScreenIndex = nextIndex;
		}
	};

	onNext = () => {
		const nextIndex = this._activeScreenIndex + 1;
		if (this.screens[nextIndex]) {
			this._activeScreenIndex = nextIndex;
		}
		if (nextIndex === this.screens.length) {
			this._showWelcomeScreen = true;
		}
	};

	onSelect = (option: string) => {
		const nextIndex = Number(option);
		if (this.screens[nextIndex]) {
			this._activeScreenIndex = nextIndex;
		}
	};

	reset = () => {
		if (this.isOpen) {
			this._activeScreenIndex = 0;
			this._showWelcomeScreen = false;
		}
	};

	init(): void {
		this._tutorialDisposer = reaction(() => this.isOpen, this.reset);
	}

	dispose() {
		this._tutorialDisposer?.();
	}
}
