import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalDesktopShareController} from "views/components/modals/modal_desktop_share/modal_desktop_share.controller";
import {Box, Button, Dialog, DialogContent, IconButton, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	top: 6px;
	right: 6px;
`;

export const ModalDesktopShare: React.FC = observer(() => {
	const {i18n, isOpen, close, shareFacebook, shareTwitter} =
		useViewController<IModalDesktopShareController>(Bindings.ModalDesktopShareController);

	return (
		<Dialog open={isOpen} onClose={close} maxWidth={"sm"}>
			<DialogContent>
				<Stack alignItems={"center"} sx={{paddingY: "40px"}} gap={"18px"}>
					<CloseBlock>
						<IconButton
							sx={{minWidth: 0}}
							onClick={close}
							color={"info"}
							size={"small"}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>

					<Typography variant="h3">
						{i18n.t("share.modal.header", "SHARE YOUR GRID")}
					</Typography>

					<Box display="flex" gap="10px" justifyContent="center">
						<Button variant="contained" color="primary" onClick={shareFacebook}>
							{i18n.t("share.modal.facebook", "Facebook")}
						</Button>
						<Button variant="contained" color="secondary" onClick={shareTwitter}>
							{i18n.t("share.modal.twitter", "Twitter")}
						</Button>
					</Box>
				</Stack>
			</DialogContent>
		</Dialog>
	);
});
