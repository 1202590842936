import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IPredictorGridPlayerButtonController} from "views/components/predictor_grid_player_button/predictor_grid_player_button.controller";
import styled from "@emotion/styled";
import {ReactComponent as AddIcon} from "assets/img/icons/add.svg";
import {Link} from "react-router-dom";
import background from "assets/img/playerBackground.svg";
import {Box, Typography, alpha} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as Lock} from "assets/img/icons/lockWhite.svg";
import {ReactComponent as Edit} from "assets/img/icons/edit.svg";

const GridButton = styled(Link)`
	height: 90px;
	width: 100%;
	background: transparent;
	border: 2px solid var(--masterbrand-light, #e9f5ed);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease-in-out;

	&.disabled {
		pointer-events: none;
	}

	&:hover:not(.disabled) {
		border: 2px solid ${({theme}) => theme.palette.eventPrimary.main};
		background: ${({theme}) => alpha(theme.palette.eventPrimary.main, 0.2)};

		svg {
			fill: ${({theme}) => theme.palette.eventPrimary.main};
		}
	}

	svg {
		height: 32px;
		width: 32px;
		transition: fill 0.2s ease-in-out;
	}

	@media screen and (min-width: 900px) {
		height: 116px;

		svg {
			height: 52px;
			width: 52px;
		}
	}

	@media screen and (min-width: 1100px) {
		height: 135px;
	}

	@media screen and (min-width: 1440px) {
		height: 160px;
	}
`;

const GridPlayer = styled(Link)`
	height: 90px;
	width: 100%;
	background: rgba(233, 245, 237, 0.5);
	background-image: url(${background});
	background-size: cover;
	background-position: center;
	border: 2px solid var(--masterbrand-light, #e9f5ed);
	color: var(--masterbrand-light, #e6fff2);
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	transition: all 0.2s ease-in-out;

	&.disabled {
		pointer-events: none;
	}

	&:hover:not(.disabled) {
		border: 2px solid ${({theme}) => theme.palette.eventPrimary.main};
		background: ${({theme}) => alpha(theme.palette.eventPrimary.main, 0.2)};
	}

	@media screen and (min-width: 900px) {
		height: 116px;
	}

	@media screen and (min-width: 1100px) {
		height: 135px;
	}

	@media screen and (min-width: 1440px) {
		height: 160px;
	}
`;

const Images = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 12px;
	padding: 1px 6px;
	padding-bottom: 0;
	width: calc((100% / 3 - 4px) - (16px / 3));

	@media screen and (min-width: 900px) {
		gap: 24px;
		width: calc((100% / 3 - 4px) - (32px / 3));
	}
`;

const PlayerImage = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;

	img {
		height: 80px;
	}

	@media screen and (min-width: 1100px) {
		img {
			height: 110px;
		}
	}

	@media screen and (min-width: 1440px) {
		img {
			height: 150px;
		}
	}
`;

const PlayerDetails = styled.div`
	width: 0;
	text-transform: uppercase;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
`;

const FlagWrapper = styled.div`
	position: relative;
	width: 44px;

	@media screen and (min-width: 1100px) {
		width: 60px;
	}

	@media screen and (min-width: 1440px) {
		width: 80px;
	}
`;

const PointsWrapper = styled.div`
	border-radius: 100px;
	background: ${({theme}) => theme.palette.eventSecondary.main};
	padding: 4px 6px;
	text-align: center;
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 50%);
`;

const LockIcon = styled(Lock)`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);

	@media screen and (min-width: 1100px) {
		width: 32px;
		height: 32px;
	}

	@media screen and (min-width: 1440px) {
		width: 44px;
		height: 44px;
	}
`;

const EditIcon = styled(Edit)`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);

	@media screen and (min-width: 1100px) {
		width: 32px;
		height: 32px;
	}

	@media screen and (min-width: 1440px) {
		width: 44px;
		height: 44px;
	}
`;

interface IProps {
	gridId: number;
	readonly: boolean;
}

export const PredictorGridPlayerButton: React.FC<IProps> = observer(({gridId, readonly}) => {
	const {player, country, isLocked, isMatchComplete, points} =
		useViewController<IPredictorGridPlayerButtonController>(
			Bindings.PredictorGridPlayerButtonController,
			{gridId}
		);

	if (!player) {
		return (
			<GridButton to={`/predictor/player/${gridId}`} className={isLocked ? "disabled" : ""}>
				<Exist when={!isLocked}>
					<AddIcon />
				</Exist>
				<Exist when={isLocked}>
					<Lock />
				</Exist>
			</GridButton>
		);
	}

	return (
		<GridPlayer
			to={`/predictor/player/${gridId}`}
			className={isLocked || readonly ? "disabled" : ""}>
			<Images>
				<FlagWrapper>
					{country && <Box component="img" src={country.flag} width="100%" />}
					<Exist when={!readonly}>
						<Exist when={!isLocked}>
							<EditIcon />
						</Exist>
						<Exist when={isLocked}>
							<Exist when={!isMatchComplete}>
								<LockIcon />
							</Exist>
							<Exist when={isMatchComplete}>
								<PointsWrapper>
									<Typography
										fontSize="10px"
										fontWeight="500"
										color="var(--masterbrand-dark, #19000A)"
										letterSpacing="1.25px">
										+{points}
									</Typography>
								</PointsWrapper>
							</Exist>
						</Exist>
					</Exist>
				</FlagWrapper>
				<Typography variant="h1" lineHeight="20px">
					{country?.abbreviation}
				</Typography>
			</Images>
			<PlayerDetails>
				<Typography variant="h4" sx={{fontSize: {xl: "24px"}}}>
					{player.firstName}
				</Typography>
				<Typography component="h3" variant="h1" sx={{fontSize: {xl: "60px"}}} noWrap>
					{player.lastName}
				</Typography>
			</PlayerDetails>
			<PlayerImage>
				<img src={player.headshot} alt={player.lastName} />
			</PlayerImage>
		</GridPlayer>
	);
});
