import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {Countdown} from "data/utils";
import {noop} from "lodash";

interface ITimer {
	seconds: string;
	minutes: string;
	hours: string;
	days: string;
}

interface IParams {
	date: Date | string;
	onComplete?: () => void;
	format?: string;
}

export interface ITimerController extends ViewController<IParams> {
	get timer(): ITimer;
	get formattedTime(): string | null;
}

const defaultValue = "00";

@injectable()
export class TimerController implements ITimerController {
	private _date = new Date();
	private _format = "DDD:HHH:MMM:SSS";
	private _onComplete?: () => void = noop;
	private _innerTimer: Countdown | null = null;

	@observable _timer: ITimer = {
		days: defaultValue,
		minutes: defaultValue,
		seconds: defaultValue,
		hours: defaultValue,
	};

	constructor() {
		makeAutoObservable(this);
	}

	get timer(): ITimer {
		return this._timer;
	}

	get formattedTime(): string | null {
		const {days, hours, minutes, seconds} = this._timer;

		return this._format
			.replace("DD", days)
			.replace(`${defaultValue}D:`, "")
			.replace("HH", hours)
			.replace(`${defaultValue}H:`, "")
			.replace("MM", minutes)
			.replace(`${defaultValue}M:`, "")
			.replace("SS", seconds);
	}

	init(param: IParams) {
		this._date = new Date(param.date);
		this._onComplete = param.onComplete;
		this.start();
	}

	dispose() {
		this._innerTimer?.clear();
	}

	onChange(param: IParams) {
		this._date = new Date(param.date);
		this._onComplete = param.onComplete;
		this._innerTimer?.updateDate(this._date);
	}

	start() {
		this._innerTimer = new Countdown(new Date(this._date));
		this._innerTimer
			.onTick((days, hours, minutes, seconds) => {
				this._timer = {
					days: String(days).padStart(2, "0"),
					minutes: String(minutes).padStart(2, "0"),
					seconds: String(seconds).padStart(2, "0"),
					hours: String(hours).padStart(2, "0"),
				};
			})
			.onComplete(() => {
				this._onComplete?.();
			})
			.run();
	}
}
