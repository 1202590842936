import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {IAxiosApiErrorGeneral} from "data/types/error";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState, RoundStatus, ShareType, Slot, SocialNetwork} from "data/enums";
import {isEqual} from "lodash";
import type {IEvent, IRoundsStore} from "data/stores/rounds/rounds.store";
import {STAGE_TRANSLATION_MAP} from "data/constants";
import {share} from "data/utils";
import type {IUserStore} from "data/stores/user/user.store";

interface IParam {
	gridId: number;
}

export interface IPredictorGridController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get arePredictionsComplete(): boolean;
	get isLocked(): boolean;
	get isComplete(): boolean;
	get points(): number | "-";
	get title(): string;
	get event(): IEvent | undefined;
	get hasMultipleRounds(): boolean;
	get isAuthorized(): boolean;
	autofill: () => void;
	resetSlot: () => void;
	share: () => void;
}

@injectable()
export class PredictorGridController implements IPredictorGridController {
	@observable private _isNarrowScreen: boolean = false;
	@observable private _requestState = RequestState.IDLE;
	@observable private _gridId: number | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get round() {
		if (!this._gridId) {
			return;
		}
		return this._roundsStore.getRoundById(this._gridId);
	}

	get predictions() {
		if (!this._gridId) {
			return {};
		}
		return this._predictionsStore.getPredictionsByGridID(this._gridId);
	}

	get arePredictionsComplete() {
		if (!this._gridId) {
			return false;
		}

		return Boolean(
			this._predictionsStore.isAllCountryPredictionsMade(this._gridId) &&
				this.predictions.slot7
		);
	}

	get isLocked() {
		if (!this._gridId) {
			return true;
		}
		return this._predictionsStore.isGridLocked(this._gridId);
	}

	get isComplete() {
		if (!this.round) {
			return false;
		}
		return this.round.status === RoundStatus.Complete;
	}

	get points() {
		if (!this._gridId) {
			return "-";
		}
		const points = this._predictionsStore.getPointsByGridID(this._gridId);
		if (points) {
			return points.gridPoints;
		}
		return 0;
	}

	get title() {
		if (!this.round) {
			return "";
		}
		const translation = STAGE_TRANSLATION_MAP.get(this.round.stage) ?? "";
		return this.i18n.t(translation);
	}

	get event() {
		if (!this.round) {
			return;
		}
		return this.round.event;
	}

	get hasMultipleRounds() {
		return this._roundsStore.currentEventRounds.length > 1;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get isMobileShareVisible() {
		return "share" in navigator && this._isNarrowScreen;
	}

	private get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 960px)");
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	private onError = (err: IAxiosApiErrorGeneral) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};

	@action autofill = () => {
		if (this._gridId) {
			this._predictionsStore
				.autofill(this._gridId)
				.then(this.onSuccess)
				.then(() => {
					this._roundsStore.selectedRoundId = this._gridId;
					this._modalsStore.showModal(ModalType.REVIEW_GRID);
				})
				.catch(this.onError);
		}
	};

	@action resetSlot = () => {
		this._predictionsStore.selectedSlot = Slot.Slot1;
	};

	@action private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	init(param: IParam): void {
		this._gridId = param.gridId;

		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);
	}

	dispose() {
		this.narrowScreenWatcher.removeEventListener("change", this.updateNarrowScreenFlag);
	}

	onChange(param: IParam): void {
		this._gridId = param.gridId;
	}

	share = () => {
		if (!this._gridId || !this._userStore.user || !this.event) {
			return;
		}

		const message = this._predictionsStore.getShareMessage(this.event.name, this.predictions);

		if (this.isMobileShareVisible) {
			share({
				socialNetwork: SocialNetwork.Mobile,
				type: ShareType.Prediction,
				lang: this.i18n.lang,
				gridId: this._gridId,
				userId: this._userStore.user.id,
				message,
			});
			return;
		}

		this._modalsStore.showModal(ModalType.DESKTOP_SHARE, {
			gridId: this._gridId,
			message,
		});
	};
}
