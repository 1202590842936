import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalRegisterController} from "views/components/modals/modal_register/modal_register.controller";
import {useKeycloak} from "@react-keycloak/web";
import styled from "@emotion/styled";
import {get, map} from "lodash";
import {getKcToken} from "data/utils/helpers";
import {Box, Button, FormControl, MenuItem, Modal, Typography} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {Preloader} from "views/components/preloader";
import {Checkbox, Select, SelectFormControl} from "views/components/form";
import logo from "assets/img/logos/svnsWhite.svg";
import {useLocation} from "react-router";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	outline: none;
	max-width: 600px;
	width: 100%;
	border-radius: 22px;
	color: var(--masterbrand-light, #e6fff2);
	border: 1px solid var(--masterbrand-light, #e6fff2);
	background: var(--masterbrand-dark, #19000a);
	padding: 44px 20px 40px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}
`;

const ContentWrapper = styled.div`
	max-width: 320px;
`;

const StyledFormControl = styled(SelectFormControl)`
	color: #fff;
	margin-bottom: 16px;

	&.MuiFormControl-root {
		.MuiInputLabel-root,
		.MuiInputLabel-root.Mui-focused {
			color: var(--masterbrand-light, #e6fff2);
		}

		.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: #fff;
		}

		.MuiSvgIcon-root {
			color: #fff;
		}
	}
`;

const StyledCheckbox = styled(Checkbox)`
	min-width: 19px;
	width: 19px;
	height: 19px;

	input:checked + svg {
		color: var(--masterbrand-dark, #19000a);
	}

	input:focus + svg + span {
		border-color: #fff;
	}

	input:checked + svg + span {
		background: #fff;
	}
`;

const FormControlStyled = styled(FormControl)`
	align-items: center;
	flex-direction: initial;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
	margin-bottom: 16px;

	p {
		margin-left: 15px;

		a {
			font-weight: 700;
		}
	}
`;

const ButtonStyled = styled(Button)`
	margin-top: 10px;
`;

export const ModalRegister: React.FC = observer(() => {
	const location = useLocation();
	const {keycloak} = useKeycloak();
	const ssoSupportingCountry = get(keycloak, "idTokenParsed.supportingCountry", null) as
		| string
		| null;

	const {
		isOpen,
		i18n,
		countries,
		selectedTerms,
		toggleSelectedTerms,
		sendRegisterData,
		supportedCountry,
		changeCountry,
		isDisabled,
		getIsLoading,
	} = useViewController<IModalRegisterController>(Bindings.ModalRegisterController, {
		ssoSupportingCountry,
		pathname: location.pathname,
	});
	const kc_token = getKcToken();

	const handleRegister = useCallback(() => {
		if (kc_token) {
			sendRegisterData(kc_token);
		} else if (keycloak.token) {
			sendRegisterData(keycloak.token);
		}
	}, [keycloak, sendRegisterData, kc_token]);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<Box component="img" src={logo} alt="SVNS" mb="24px" />
				<Typography variant="h3" mb="28px">
					{i18n.t("terms.modal.header", "GET STARTED")}
				</Typography>
				<ContentWrapper>
					<Exist when={!ssoSupportingCountry || ssoSupportingCountry === ""}>
						<StyledFormControl>
							<Select
								name="squad"
								label={i18n.t("terms.modal.country_supported", "Country Supported")}
								value={supportedCountry}
								onChange={changeCountry}>
								{map(countries, (country) => (
									<MenuItem key={country.code} value={country.code}>
										{country.name}
									</MenuItem>
								))}
							</Select>
						</StyledFormControl>
					</Exist>
					<Typography mb="16px">
						{i18n.t(
							"terms.modal.copy",
							"To be eligible to win prizes, you must accept this game’s"
						)}{" "}
						<a href={"/help/terms-&-conditions"} target="_blank" rel="noreferrer">
							{i18n.t("terms & conditions.")}
						</a>
					</Typography>

					<FormControlStyled>
						<StyledCheckbox checked={selectedTerms} onChange={toggleSelectedTerms} />{" "}
						<Typography variant="body2">
							{i18n.t(
								"terms.modal.terms_copy",
								"By selecting this box I agree to the"
							)}{" "}
							<a href={"/help/terms-&-conditions"} target="_blank" rel="noreferrer">
								{i18n.t("Terms and Conditions")}
							</a>
						</Typography>
					</FormControlStyled>
					<Exist when={getIsLoading}>
						<Preloader />
					</Exist>
					<Exist when={!getIsLoading}>
						<ButtonStyled
							fullWidth
							disabled={isDisabled}
							onClick={handleRegister}
							variant="contained"
							color="secondary">
							{i18n.t("terms.modal.accept_button", "ACCEPT")}
						</ButtonStyled>
					</Exist>
				</ContentWrapper>
			</ModalContent>
		</Modal>
	);
});
