import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	IEventPrams,
	IOverallParams,
	IRankingsProvider,
} from "data/providers/api/rankings.provider";
import {Stage} from "data/enums";

export interface IRankRow {
	userId: number;
	username: string;
	overallRank: number | null;
	overallPoints: number | null;
	gridPoints: number | null;
}

export interface IRankings {
	rankings: IRankRow[];
	user?: IRankRow | null;
	nextPage: boolean;
}

export interface IEventRankRow {
	userId: number;
	username: string;
	eventRank: number | null;
	eventPoints: number | null;
	poolStagePoints: number | null;
	cupStagePoints: number | null;
	prevEventRank: number | null;
}

export interface IEventRankings {
	rankings: IEventRankRow[];
	user?: IEventRankRow | null;
	nextPage: boolean;
}

export interface IFilters {
	stage: Stage;
	event: string;
}

export interface IRankingsStore {
	get leaderboard(): IRankings;
	get eventLeaderboard(): IEventRankings;
	get filters(): IFilters;
	set filters(value: IFilters);

	resetFilters: () => void;
	fetch(params: IOverallParams): Promise<void>;
	fetchMore(params: IOverallParams): Promise<void>;
	fetchEvent(params: IEventPrams): Promise<void>;
	fetchMoreEvent(params: IEventPrams): Promise<void>;
}

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable private _leaderboard: IRankings = {
		rankings: [],
		nextPage: false,
	};
	@observable private _eventLeaderboard: IEventRankings = {
		rankings: [],
		nextPage: false,
	};
	@observable private _filters = {
		stage: Stage.Pool,
		event: "overall",
	};

	constructor(@inject(Bindings.RankingsProvider) readonly _rankingsProvider: IRankingsProvider) {
		makeAutoObservable(this);
	}

	get leaderboard(): IRankings {
		return this._leaderboard;
	}

	get eventLeaderboard(): IEventRankings {
		return this._eventLeaderboard;
	}

	get filters(): IFilters {
		return this._filters;
	}

	set filters(value: IFilters) {
		this._filters = value;
	}

	@action resetFilters = () => {
		this.filters = {
			stage: Stage.Pool,
			event: "overall",
		};
	};

	@action async fetch(params: IOverallParams): Promise<void> {
		const response = await this._rankingsProvider.overall(params);

		runInAction(() => {
			this._leaderboard = response.data.success.leaderboard;
		});
	}

	@action async fetchMore(params: IOverallParams): Promise<void> {
		const response = await this._rankingsProvider.overall(params);

		runInAction(() => {
			const {rankings, ...otherData} = response.data.success.leaderboard;

			this._leaderboard = {
				rankings: [...this._leaderboard.rankings, ...rankings],
				...otherData,
			};
		});
	}

	@action async fetchEvent(params: IEventPrams): Promise<void> {
		const response = await this._rankingsProvider.event(params);

		runInAction(() => {
			this._eventLeaderboard = response.data.success.leaderboard;
		});
	}

	@action async fetchMoreEvent(params: IEventPrams): Promise<void> {
		const response = await this._rankingsProvider.event(params);

		runInAction(() => {
			const {rankings, ...otherData} = response.data.success.leaderboard;

			this._eventLeaderboard = {
				rankings: [...this._eventLeaderboard.rankings, ...rankings],
				...otherData,
			};
		});
	}
}
