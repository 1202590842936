import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IPredictorGridButtonController} from "views/components/predictor_grid_button/predictor_grid_button.controller";
import styled from "@emotion/styled";
import {ReactComponent as AddIcon} from "assets/img/icons/add.svg";
import {Slot} from "data/enums";
import {useNavigate} from "react-router";
import {Typography, alpha} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as Lock} from "assets/img/icons/lockWhite.svg";
import {ReactComponent as Edit} from "assets/img/icons/edit.svg";

const GridButton = styled.button`
	height: 90px;
	width: 100%;
	background: transparent;
	border: 2px solid var(--masterbrand-light, #e9f5ed);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease-in-out;

	&:disabled {
		cursor: default;
	}

	&:hover:enabled {
		border: 2px solid ${({theme}) => theme.palette.eventPrimary.main};
		background: ${({theme}) => alpha(theme.palette.eventPrimary.main, 0.2)};

		svg {
			fill: ${({theme}) => theme.palette.eventPrimary.main};
		}
	}

	svg {
		height: 32px;
		width: 32px;
		transition: fill 0.2s ease-in-out;
	}

	@media screen and (min-width: 900px) {
		height: 116px;

		svg {
			height: 52px;
			width: 52px;
		}
	}

	@media screen and (min-width: 1100px) {
		height: 135px;
	}

	@media screen and (min-width: 1440px) {
		height: 160px;
	}
`;

const GridCountry = styled.button`
	height: 90px;
	width: 100%;
	background: rgba(233, 245, 237, 0.5);
	border: 2px solid var(--masterbrand-light, #e9f5ed);
	color: var(--masterbrand-light, #e6fff2);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 12px;
	transition: all 0.2s ease-in-out;

	&:disabled {
		cursor: default;
	}

	&:hover:enabled {
		border: 2px solid ${({theme}) => theme.palette.eventPrimary.main};
		background: ${({theme}) => alpha(theme.palette.eventPrimary.main, 0.2)};
	}

	@media screen and (min-width: 900px) {
		height: 116px;
	}

	@media screen and (min-width: 1100px) {
		height: 135px;
		gap: 24px;
	}

	@media screen and (min-width: 1440px) {
		height: 160px;
	}
`;

const FlagWrapper = styled.div`
	position: relative;
	width: 44px;
	margin: 0 auto;

	@media screen and (min-width: 1100px) {
		width: 60px;
	}

	@media screen and (min-width: 1440px) {
		width: 80px;
	}
`;

const Flag = styled.img`
	width: 100%;
	vertical-align: middle;
`;

const PointsWrapper = styled.div`
	border-radius: 100px;
	background: ${({theme}) => theme.palette.eventSecondary.main};
	padding: 4px 6px;
	text-align: center;
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 50%);
`;

const LockIcon = styled(Lock)`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);

	@media screen and (min-width: 1100px) {
		width: 32px;
		height: 32px;
	}

	@media screen and (min-width: 1440px) {
		width: 44px;
		height: 44px;
	}
`;

const EditIcon = styled(Edit)`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);

	@media screen and (min-width: 1100px) {
		width: 32px;
		height: 32px;
	}

	@media screen and (min-width: 1440px) {
		width: 44px;
		height: 44px;
	}
`;

interface IProps {
	gridId: number;
	slot: Slot;
	readonly: boolean;
}

export const PredictorGridButton: React.FC<IProps> = observer(({gridId, slot, readonly}) => {
	const {country, isLocked, isMatchComplete, points, openSelect} =
		useViewController<IPredictorGridButtonController>(Bindings.PredictorGridButtonController, {
			gridId,
			slot,
			navigate: useNavigate(),
		});

	if (!country) {
		return (
			<GridButton onClick={openSelect} disabled={isLocked}>
				<Exist when={!isLocked}>
					<AddIcon />
				</Exist>
				<Exist when={isLocked}>
					<Lock />
				</Exist>
			</GridButton>
		);
	}

	return (
		<GridCountry disabled={isLocked || readonly} onClick={openSelect}>
			<FlagWrapper>
				<Flag src={country.flag} alt={country.abbreviation} />
				<Exist when={!readonly}>
					<Exist when={!isLocked}>
						<EditIcon />
					</Exist>
					<Exist when={isLocked}>
						<Exist when={!isMatchComplete}>
							<LockIcon />
						</Exist>
						<Exist when={isMatchComplete}>
							<PointsWrapper>
								<Typography
									fontSize="10px"
									fontWeight="500"
									color="var(--masterbrand-dark, #19000A)"
									letterSpacing="1.25px">
									+{points}
								</Typography>
							</PointsWrapper>
						</Exist>
					</Exist>
				</Exist>
			</FlagWrapper>
			<Typography variant="h1" lineHeight="20px">
				{country.abbreviation}
			</Typography>
		</GridCountry>
	);
});
