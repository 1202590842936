import React, {Fragment, lazy} from "react";
import {Route} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";

const Landing = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const Predictor = lazy(retryFailLoad(() => import("views/pages/predictor/predictor.page")));
const PredictorHome = lazy(
	retryFailLoad(() => import("views/pages/predictor_home/predictor_home.page"))
);
const PredictorCountry = lazy(
	retryFailLoad(() => import("views/pages/predictor_country/predictor_country.page"))
);
const PredictorPlayer = lazy(
	retryFailLoad(() => import("views/pages/predictor_player/predictor_player.page"))
);
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);
const BackdoorPage = lazy(retryFailLoad(() => import("views/pages/backdoor/backdoor.component")));
const Rankings = lazy(retryFailLoad(() => import("views/pages/rankings/rankings.page")));
const Logout = lazy(retryFailLoad(() => import("views/pages/logout/logout.component")));
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));

export const RootRoutes = (
	<Fragment>
		<Route path="/" element={<Landing />} />
		<Route path="/predictor" element={<Predictor />}>
			<Route path="country/:gridId" element={<PredictorCountry />} />
			<Route path="player/:gridId" element={<PredictorPlayer />} />
			<Route path="" element={<PredictorHome />} />
		</Route>
		<Route element={<NotAuthOnlyRoute />}>
			{process.env.REACT_APP_ENVIRONMENT !== "production" ? (
				<Route path={"/backdoor"} element={<BackdoorPage />} />
			) : (
				""
			)}
		</Route>
		<Route element={<PrivateRoute />}>
			<Route path="/logout" element={<Logout />} />
			<Route path="/account" element={<MyAccount />} />
			<Route path="rankings" element={<Rankings />} />
		</Route>
		<Route path="components" element={<ComponentsReview />} />
		<Route path="help/*" element={<Help />} />
		<Route path="*" element={<NotFound />} />
	</Fragment>
);

export default RootRoutes;
