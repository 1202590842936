import {Competition, Gender, Locale, Slot, SlotPoints, Stage} from "data/enums";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || "";
export const SSO_CLIENT_APP_ID = process.env.REACT_APP_SSO_APP_CLIENT_ID || "";
export const SSO_CLIENT_REALM = process.env.REACT_APP_SSO_CLIENT_REALM || "";
export const SSO_URL = process.env.REACT_APP_SSO_URL || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\\-!@#$%^&*\\(\\)_+\\|~=`\\{\\}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const SUPPORTED_LOCALES = [Locale.EN_US];

export const COUNTRY_SLOTS = [
	Slot.Slot1,
	Slot.Slot2,
	Slot.Slot3,
	Slot.Slot4,
	Slot.Slot5,
	Slot.Slot6,
];

export const SLOT_COMPETITION_MAP = new Map([
	[Slot.Slot1, [Competition.Womens]],
	[Slot.Slot2, [Competition.Womens]],
	[Slot.Slot3, [Competition.Womens]],
	[Slot.Slot4, [Competition.Mens]],
	[Slot.Slot5, [Competition.Mens]],
	[Slot.Slot6, [Competition.Mens]],
	[Slot.Slot7, [Competition.Womens, Competition.Mens]],
]);

export const SLOT_GENDER_MAP = new Map([
	[Slot.Slot1, [Gender.Womens]],
	[Slot.Slot2, [Gender.Womens]],
	[Slot.Slot3, [Gender.Womens]],
	[Slot.Slot4, [Gender.Mens]],
	[Slot.Slot5, [Gender.Mens]],
	[Slot.Slot6, [Gender.Mens]],
	[Slot.Slot7, [Gender.Womens, Gender.Mens]],
]);

export const SLOT_POINTS_MAP = new Map([
	[Slot.Slot1, SlotPoints.Slot1],
	[Slot.Slot2, SlotPoints.Slot2],
	[Slot.Slot3, SlotPoints.Slot3],
	[Slot.Slot4, SlotPoints.Slot4],
	[Slot.Slot5, SlotPoints.Slot5],
	[Slot.Slot6, SlotPoints.Slot6],
	[Slot.Slot7, SlotPoints.Slot7],
]);

export const STAGE_TRANSLATION_MAP = new Map([
	[Stage.Pool, "grid.stage.pool"],
	[Stage.Cup, "grid.stage.cup"],
]);

export const COUNTRY_CODE_MAP = new Map([
	["ARG", "AR"],
	["AUS", "AU"],
	["BRA", "BR"],
	["CAN", "CA"],
	["CHL", "CL"],
	["COL", "CO"],
	["DEU", "DE"],
	["ESP", "ES"],
	["FJI", "FJ"],
	["FRA", "FR"],
	["GBR", "GB"],
	["HKG", "HK"],
	["IRL", "IE"],
	["JPN", "JP"],
	["KEN", "KE"],
	["NZL", "NZ"],
	["PNG", "PG"],
	["POL", "PL"],
	["WSM", "WS"],
	["ZAF", "ZA"],
	["TON", "TO"],
	["USA", "US"],
	["URY", "UY"],
]);
