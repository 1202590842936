import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalShareGridController} from "views/components/modals/modal_share_grid/modal_share_grid.controller";
import styled from "@emotion/styled";
import {Box, Button, ButtonBase, Dialog, Typography} from "@mui/material";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeRound.svg";
import {ReactComponent as ShareIcon} from "assets/img/icons/share.svg";
import {useTheme} from "@emotion/react";

const ModalContent = styled.div`
	width: 100%;
	background: ${({theme}) => theme.palette.eventSecondary.main};
	padding: 6px 20px 30px;
	text-align: center;

	@media screen and (max-width: 640px) {
		padding: 6px 6px 30px;
	}

	button {
		max-width: 300px;
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 20px;
`;

export const ModalShareGrid: React.FC = observer(() => {
	const theme = useTheme();
	const {i18n, isOpen, close, share} = useViewController<IModalShareGridController>(
		Bindings.ModalShareGridController
	);

	return (
		<Dialog open={isOpen} scroll="body">
			<ModalContent>
				<CloseBlock>
					<ButtonBase sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</ButtonBase>
				</CloseBlock>
				<Box component="img" src={theme.images.logo} width="96px" mb="20px" />
				<Typography variant="h2" sx={{mb: "6px"}}>
					{i18n.t("share.modal.header", "SHARE YOUR GRID")}
				</Typography>
				<Typography variant="h4" textTransform="uppercase" sx={{mb: "18px"}}>
					{i18n.t("share.modal.desc", "YOUR PREDICTION FOR HSBC SVNS HAS NOW BEEN SAVED")}
				</Typography>

				<Box display="flex" justifyContent="center">
					<Button
						size="large"
						variant="contained"
						color="eventPrimary"
						onClick={share}
						endIcon={<ShareIcon />}>
						{i18n.t("share.modal.button", "SHARE GRID")}
					</Button>
				</Box>
			</ModalContent>
		</Dialog>
	);
});
