import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	ForgotPasswordController,
	type IForgotPasswordController,
} from "views/pages/forgot_password/forgot_password.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	type ICreateLeagueController,
	CreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {BackdoorController, IBackdoorController} from "views/pages/backdoor/backdoor.controller";
import {
	type IPredictorController,
	PredictorController,
} from "views/pages/predictor/predictor.controller";
import {
	type IPredictorGridController,
	PredictorGridController,
} from "views/components/predictor_grid/predictor_grid.controller";
import {
	type IPredictorGridButtonController,
	PredictorGridButtonController,
} from "views/components/predictor_grid_button/predictor_grid_button.controller";
import {
	type IDaySelectorController,
	DaySelectorController,
} from "views/components/day_selector/day_selector.controller";
import {type IPredictionsStore, PredictionsStore} from "data/stores/predictions/predictions.store";
import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {
	type IPredictorCountryController,
	PredictorCountryController,
} from "views/pages/predictor_country/predictor_country.controller";
import {
	type IPredictorHomeController,
	PredictorHomeController,
} from "views/pages/predictor_home/predictor_home.controller";
import {
	type IModalInfoController,
	ModalInfoController,
} from "views/components/modals/modal_info/modal_info.controller";
import {
	type IPredictionsApiProvider,
	PredictionsApiProvider,
} from "data/providers/api/predictions.api.provider";
import {
	type IRankingsController,
	RankingsController,
} from "views/pages/rankings/rankings.controller";
import {type IRankingsProvider, RankingsProvider} from "data/providers/api/rankings.provider";
import {type IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";
import {
	type IPredictorPlayerController,
	PredictorPlayerController,
} from "views/pages/predictor_player/predictor_player.controller";
import {
	type IPlayerPoolController,
	PlayerPoolController,
} from "views/components/player_pool/player_pool.controller";
import {
	type IModalReviewGridController,
	ModalReviewGridController,
} from "views/components/modals/modal_review_grid/modal_review_grid.controller";
import {
	type IPredictorGridPlayerButtonController,
	PredictorGridPlayerButtonController,
} from "views/components/predictor_grid_player_button/predictor_grid_player_button.controller";
import {
	type IModalRegisterController,
	ModalRegisterController,
} from "views/components/modals/modal_register/modal_register.controller";
import {
	type IPlayerPoolRowController,
	PlayerPoolRowController,
} from "views/components/player_pool_row/player_pool_row.controller";
import {
	type INavigationController,
	NavigationController,
} from "views/components/header/navigation.controller";
import {type ILogoutController, LogoutController} from "views/pages/logout/logout.controller";
import {
	type ITutorialModalController,
	TutorialModalController,
} from "views/components/modals/tutorial_modal/tutorial_modal.controller";
import {type ITimerController, TimerController} from "views/components/timer/timer.controller";
import {
	type IPredictorLockoutLabelController,
	PredictorLockoutLabelController,
} from "views/components/predictor_lockout_label/predictor_lockout_label.controller";
import {
	type ILockoutTimerController,
	LockoutTimerController,
} from "views/components/lockout_timer/lockout_timer.controller";
import {
	type IModalPromptInnerController,
	ModalPromptInnerController,
} from "views/components/modals/modal_prompt_inner/modal_prompt_inner.controller";
import {
	type IPredictorGridOtherController,
	PredictorGridOtherController,
} from "views/components/predictor_grid_other/predictor_grid_other.controller";
import {
	type IEventThemeController,
	EventThemeController,
} from "views/components/event_theme/event_theme.controller";
import {
	type IRankingsRowController,
	RankingsRowController,
} from "views/components/rankings/rankings_row/rankings_row.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {
	type ILandingUnauthorizedController,
	LandingUnauthorizedController,
} from "views/pages/landing/landing_unauthorized/landing_unauthorized.controller";
import {
	type ILandingAuthorizedController,
	LandingAuthorizedController,
} from "views/pages/landing/landing_authorized/landing_authorized.controller";
import {
	type ILandingEventController,
	LandingEventController,
} from "views/components/landing_event/landing_event.controller";
import {
	type ILandingUpcomingEventsController,
	LandingUpcomingEventsController,
} from "views/components/landing_upcoming_events/landing_upcoming_events.controller";
import {
	type IModalShareGridController,
	ModalShareGridController,
} from "views/components/modals/modal_share_grid/modal_share_grid.controller";
import {
	type IModalDesktopShareController,
	ModalDesktopShareController,
} from "views/components/modals/modal_desktop_share/modal_desktop_share.controller";
import {
	type IModalLoginController,
	ModalLoginController,
} from "views/components/modals/modal_login/modal_login.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IPredictionsApiProvider>(Bindings.PredictionsApiProvider).to(PredictionsApiProvider);
	bind<IRankingsProvider>(Bindings.RankingsProvider).to(RankingsProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IPredictionsStore>(Bindings.PredictionsStore).to(PredictionsStore).inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IForgotPasswordController>(Bindings.ForgotPasswordController).to(ForgotPasswordController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<IBackdoorController>(Bindings.BackdoorController).to(BackdoorController);
	bind<IPredictorController>(Bindings.PredictorController).to(PredictorController);
	bind<IPredictorGridController>(Bindings.PredictorGridController).to(PredictorGridController);
	bind<IPredictorGridButtonController>(Bindings.PredictorGridButtonController).to(
		PredictorGridButtonController
	);
	bind<IDaySelectorController>(Bindings.DaySelectorController).to(DaySelectorController);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<IPredictorCountryController>(Bindings.PredictorCountryController).to(
		PredictorCountryController
	);
	bind<IPredictorHomeController>(Bindings.PredictorHomeController).to(PredictorHomeController);
	bind<IModalInfoController>(Bindings.ModalInfoController).to(ModalInfoController);
	bind<IRankingsController>(Bindings.RankingsController).to(RankingsController);
	bind<IPredictorPlayerController>(Bindings.PredictorPlayerController).to(
		PredictorPlayerController
	);
	bind<IPlayerPoolController>(Bindings.PlayerPoolController).to(PlayerPoolController);
	bind<IModalReviewGridController>(Bindings.ModalReviewGridController).to(
		ModalReviewGridController
	);
	bind<IPredictorGridPlayerButtonController>(Bindings.PredictorGridPlayerButtonController).to(
		PredictorGridPlayerButtonController
	);
	bind<IModalRegisterController>(Bindings.ModalRegisterController).to(ModalRegisterController);
	bind<IPlayerPoolRowController>(Bindings.PlayerPoolRowController).to(PlayerPoolRowController);
	bind<INavigationController>(Bindings.NavigationController).to(NavigationController);
	bind<ILogoutController>(Bindings.LogoutController).to(LogoutController);
	bind<ITutorialModalController>(Bindings.TutorialModalController).to(TutorialModalController);
	bind<ITimerController>(Bindings.TimerController).to(TimerController);
	bind<IPredictorLockoutLabelController>(Bindings.PredictorLockoutLabelController).to(
		PredictorLockoutLabelController
	);
	bind<ILockoutTimerController>(Bindings.LockoutTimerController).to(LockoutTimerController);
	bind<IModalPromptInnerController>(Bindings.ModalPromptInnerController).to(
		ModalPromptInnerController
	);
	bind<IPredictorGridOtherController>(Bindings.PredictorGridOtherController).to(
		PredictorGridOtherController
	);
	bind<IEventThemeController>(Bindings.EventThemeController).to(EventThemeController);
	bind<IRankingsRowController>(Bindings.RankingsRowController).to(RankingsRowController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ILandingUnauthorizedController>(Bindings.LandingUnauthorizedController).to(
		LandingUnauthorizedController
	);
	bind<ILandingAuthorizedController>(Bindings.LandingAuthorizedController).to(
		LandingAuthorizedController
	);
	bind<ILandingEventController>(Bindings.LandingEventController).to(LandingEventController);
	bind<ILandingUpcomingEventsController>(Bindings.LandingUpcomingEventsController).to(
		LandingUpcomingEventsController
	);
	bind<IModalShareGridController>(Bindings.ModalShareGridController).to(ModalShareGridController);
	bind<IModalDesktopShareController>(Bindings.ModalDesktopShareController).to(
		ModalDesktopShareController
	);
	bind<IModalLoginController>(Bindings.ModalLoginController).to(ModalLoginController);
});
