import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, ShareType, SocialNetwork} from "data/enums";
import {share} from "data/utils";
import type {IUserStore} from "data/stores/user/user.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IModalShareGridController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get gridId(): number | undefined;
	close: () => void;
	share: () => void;
}

@injectable()
export class ModalShareGridController implements IModalShareGridController {
	@observable private _isNarrowScreen: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SHARE_GRID;
	}

	get gridId() {
		return this._modalsStore.modalContent?.gridId;
	}

	get event() {
		if (!this.gridId) {
			return;
		}
		return this._roundsStore.getRoundById(this.gridId)?.event;
	}

	get predictions() {
		if (!this.gridId) {
			return {};
		}
		return this._predictionsStore.getPredictionsByGridID(this.gridId);
	}

	get isMobileShareVisible() {
		return "share" in navigator && this._isNarrowScreen;
	}

	private get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 960px)");
	}

	@action private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	init(): void {
		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);
	}

	dispose() {
		this.narrowScreenWatcher.removeEventListener("change", this.updateNarrowScreenFlag);
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	share = () => {
		if (!this.gridId || !this._userStore.user || !this.event) {
			return;
		}

		const message = this._predictionsStore.getShareMessage(this.event.name, this.predictions);

		if (this.isMobileShareVisible) {
			share({
				socialNetwork: SocialNetwork.Mobile,
				type: ShareType.Prediction,
				lang: this.i18n.lang,
				gridId: this.gridId,
				userId: this._userStore.user.id,
				message,
			});
			return;
		}

		this._modalsStore.showModal(ModalType.DESKTOP_SHARE, {
			gridId: this.gridId,
			message,
		});
	};
}
