import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

export const Input = styled(TextField)`
	background: transparent;
	color: #a3a3a3;
	border-radius: 4px;
	text-align: left;

	.MuiFilledInput-root {
		background: transparent;
	}

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: var(--primaryColor);
	}

	.MuiFilledInput-root::before,
	.MuiFilledInput-root::after {
		border-radius: 3px;
	}

	.MuiFilledInput-root::after {
		border-color: #a3a3a3;
	}
`;

Input.defaultProps = {
	variant: "outlined",
	fullWidth: true,
};
