import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IAxiosApiErrorGeneral} from "data/types/error";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import type {IPredictions, IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface IPredictorController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get isBlocking(): boolean;
}

@injectable()
export class PredictorController implements IPredictorController {
	@observable private _requestState = RequestState.PENDING;
	@observable private _fetchPredictionsDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isBlocking() {
		return this._predictionsStore.hasAnyPredictionChanged;
	}

	@action async init(param: void) {
		this._requestState = RequestState.PENDING;

		await Promise.all([this._squadsStore.fetchSquads(), this._playersStore.fetchPlayers()])
			.then(this.fetchPredictions)
			.then(this.onSuccess)
			.catch(this.onError);

		this._fetchPredictionsDisposer = reaction(
			() => this._userStore.isAuthorized,
			() => {
				if (this._userStore.isAuthorized) {
					void this.fetchPredictions().catch(this.onError);
				}
			}
		);
	}

	dispose() {
		this._fetchPredictionsDisposer?.();
	}

	@action private fetchPredictions = async () => {
		const round = this._roundsStore.selectedRound;
		if (!round) {
			throw new Error("No round found");
		}

		if (this._userStore.isAuthorized) {
			await this._predictionsStore.fetchPredictions(round.event.id);
		}

		const storedPredictions = JSON.parse(
			localStorage.getItem("predictions") || "null"
		) as IPredictions | null;
		if (storedPredictions) {
			this._predictionsStore.predictions = this._predictionsStore.predictions
				.filter((prediction) => prediction.gridId !== storedPredictions.gridId)
				.concat(storedPredictions);
		}

		if (this._userStore.isAuthorized && storedPredictions) {
			localStorage.removeItem("predictions");
			await this._predictionsStore.savePredictions(storedPredictions.gridId);
			this._modalsStore.showModal(ModalType.SHARE_GRID, {
				message: "",
				gridId: storedPredictions.gridId,
			});
		}

		this._predictionsStore.setDefaultPredictions();
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (err: IAxiosApiErrorGeneral) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: getErrorMessageFromAxiosResponse(err),
		});
	};
}
