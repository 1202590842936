import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {Competition, GameStatus, RoundStatus, Slot, Stage} from "data/enums";
import {chain, first, last} from "lodash";

export interface ICategory {
	code: string;
	id: number;
	name: string;
}

export interface IEvent {
	id: number;
	name: string;
	abbreviation: string;
	dates: string;
	category1: ICategory;
	category2: ICategory;
	category3: ICategory;
}

export interface IGame {
	id: number;
	competition: Competition;
	stageName: string;
	date: string;
	status: GameStatus;
	homeId: number;
	homeScore: number | null;
	awayId: number;
	awayScore: number | null;
}

export interface IRound {
	id: number;
	event: IEvent;
	stage: Stage;
	status: RoundStatus;
	lockoutDates: string[];
	games: IGame[];
}

export interface IRoundsStore {
	get getIsLoading(): boolean;

	get list(): IRound[];

	get scheduleRounds(): IRound[];

	get currentRound(): IRound | undefined;

	get scoreRound(): IRound | undefined;

	get currentEventRounds(): IRound[];

	get selectedRoundId(): number | null;

	set selectedRoundId(id: number | null);

	get selectedRound(): IRound | undefined;

	get events(): IEvent[];

	get activeOrCompleteEvents(): IEvent[];

	get availableEvents(): IEvent[];

	get scheduledEvents(): IEvent[];

	getRoundById(roundId: number): IRound | undefined;

	getRoundsByEventId(eventId: number): IRound[];

	setRoundToLocked(roundId: number): void;

	fetchRounds(): Promise<void>;

	getEventCategoryBySlot(slot: Slot): ICategory | undefined;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _isLoading: boolean = false;
	@observable private _selectedRoundId: number | null = null;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IRound[] = [];

	get list() {
		return this._list;
	}

	get scheduleRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	get completedRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Complete);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get currentRound() {
		return this.activeRound || first(this.scheduleRounds) || last(this.list);
	}

	get scoreRound() {
		return this.activeRound || last(this.completedRounds);
	}

	private get activeRound() {
		return this.list.find((e) => e.status === RoundStatus.Playing);
	}

	get selectedRoundId() {
		return this._selectedRoundId;
	}

	set selectedRoundId(id: number | null) {
		this._selectedRoundId = id;
	}

	get selectedRound() {
		const round = this.list.find((e) => e.id === this.selectedRoundId);
		if (round) {
			return round;
		}

		return this.currentRound;
	}

	get currentEventRounds() {
		if (!this.selectedRound) {
			return [];
		}

		const eventId = this.selectedRound.event.id;
		return this.list.filter(({event}) => eventId === event.id);
	}

	get events() {
		return chain(this.list)
			.map(({event}) => event)
			.uniqBy("id")
			.value();
	}

	get activeOrCompleteEvents() {
		return chain(this.list)
			.filter(({status}) => [RoundStatus.Complete, RoundStatus.Playing].includes(status))
			.map(({event}) => event)
			.uniqBy("id")
			.value();
	}

	get availableEvents() {
		return chain(this.events)
			.filter(({id}) =>
				this.list
					.filter(({event}) => event.id === id)
					.every(
						({event, status}) =>
							event.id === this.currentRound?.event.id ||
							status !== RoundStatus.Scheduled
					)
			)
			.value();
	}

	get scheduledEvents() {
		return chain(this.events)
			.filter(({id}) =>
				this.list
					.filter(({event}) => event.id === id)
					.every(({status}) => status === RoundStatus.Scheduled)
			)
			.value();
	}

	getRoundById(gridId: number): IRound | undefined {
		return this._list.find((round) => round.id === gridId);
	}

	getRoundsByEventId(eventId: number): IRound[] {
		return this._list.filter((round) => round.event.id === eventId);
	}

	setRoundToLocked(gridId: number) {
		this._list = this._list.map((grid) => {
			if (grid.id === gridId) {
				return {
					...grid,
					status: RoundStatus.Playing,
				};
			}

			return grid;
		});
	}

	@action
	async fetchRounds() {
		const {data} = await this._jsonProvider.rounds();

		runInAction(() => {
			// Remove Dubai round that was behind secret key
			const filteredRounds = data.filter(({event}) => event.abbreviation !== "DXB");
			this._list = filteredRounds;
		});
	}

	getEventCategoryBySlot(slot: Slot): ICategory | undefined {
		if (!this.selectedRound) {
			return;
		}

		const {category1, category2, category3} = this.selectedRound.event;
		if ([Slot.Slot1, Slot.Slot4].includes(slot)) {
			return category1;
		} else if ([Slot.Slot2, Slot.Slot5].includes(slot)) {
			return category2;
		} else if ([Slot.Slot3, Slot.Slot6].includes(slot)) {
			return category3;
		}
	}
}
