import React from "react";
import {observer} from "mobx-react";
import logo from "assets/img/logos/svnsWhite.svg";
import styled from "@emotion/styled";
import {Stack, IconButton, useMediaQuery, Container} from "@mui/material";
import {HelpOutlineSharp as HelpOutlineSharpIcon} from "@mui/icons-material";
import {Exist} from "views/components/exist/exist.component";
import {NavLink} from "react-router-dom";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {DrawerNavigation} from "views/components/header/drawer_navigation/drawer_navigation.component";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";
import {DesktopNavigation} from "views/components/header/desktop_navigation/desktop_navigation.component";

const HeaderWrapper = styled(Stack)`
	flex-flow: row;
	border-bottom: 1px solid var(--masterbrand-light, #e9f5ed);
	background: var(--masterbrand-dark, #19000a);
	justify-content: space-between;
	align-items: center;
	padding: 6px 0;
	${breakpointsTheme.up("md")} {
		padding: 0;
		& > a {
			width: 120px;
			svg {
				width: 100%;
				height: auto;
			}
		}
	}
`;

const HeaderContainer = styled(Container)`
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${breakpointsTheme.up("md")} {
		gap: 100px;
	}
`;

export const Header: React.FC = observer(() => {
	const isMobile = useMediaQuery(breakpointsTheme.down("md"));

	return (
		<HeaderWrapper>
			<HeaderContainer maxWidth="xl">
				<NavLink to={"/"}>
					<img src={logo} alt="HSBC SVNS" width="62" />
				</NavLink>

				<Exist when={SecretGateController.IS_SECRET_PASSED}>
					<Exist when={!isMobile}>
						<DesktopNavigation />
					</Exist>

					<Exist when={isMobile}>
						<Stack direction={"row"} gap={"12px"}>
							<IconButton
								color={"secondary"}
								component={NavLink}
								LinkComponent={NavLink}
								to={"/help"}>
								<HelpOutlineSharpIcon />
							</IconButton>

							<DrawerNavigation />
						</Stack>
					</Exist>
				</Exist>
			</HeaderContainer>
		</HeaderWrapper>
	);
});
