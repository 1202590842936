import {theme} from "assets/theming/theme";
import cptMobile from "assets/img/events/background/mobile/cpt.svg";
import cptDesktop from "assets/img/events/background/desktop/cpt.svg";
import cptLeaderboard from "assets/img/events/leaderboard/mobile/cpt.svg";
import cptLandingMobile from "assets/img/events/landing/mobile/cpt.svg";
import cptLandingDesktop from "assets/img/events/landing/desktop/cpt.svg";
import cptLogo from "assets/img/events/logo/cpt.svg";
import cptShapeColour from "assets/img/events/shape/colour/cpt.svg";
import cptShapeDark from "assets/img/events/shape/dark/cpt.svg";
import laxMobile from "assets/img/events/background/mobile/lax.svg";
import laxDesktop from "assets/img/events/background/desktop/lax.svg";
import laxLeaderboard from "assets/img/events/leaderboard/mobile/lax.svg";
import laxLandingMobile from "assets/img/events/landing/mobile/lax.svg";
import laxLandingDesktop from "assets/img/events/landing/desktop/lax.svg";
import laxLogo from "assets/img/events/logo/lax.svg";
import laxShapeColour from "assets/img/events/shape/colour/lax.svg";
import laxShapeDark from "assets/img/events/shape/dark/lax.svg";
import madMobile from "assets/img/events/background/mobile/mad.svg";
import madDesktop from "assets/img/events/background/desktop/mad.svg";
import madLeaderboard from "assets/img/events/leaderboard/mobile/mad.svg";
import madLandingMobile from "assets/img/events/landing/mobile/mad.svg";
import madLandingDesktop from "assets/img/events/landing/desktop/mad.svg";
import madLogo from "assets/img/events/logo/mad.svg";
import madShapeColour from "assets/img/events/shape/colour/mad.svg";
import madShapeDark from "assets/img/events/shape/dark/mad.svg";
import sgpMobile from "assets/img/events/background/mobile/sgp.svg";
import sgpDesktop from "assets/img/events/background/desktop/sgp.svg";
import sgpLeaderboard from "assets/img/events/leaderboard/mobile/sgp.svg";
import sgpLandingMobile from "assets/img/events/landing/mobile/sgp.svg";
import sgpLandingDesktop from "assets/img/events/landing/desktop/sgp.svg";
import sgpLogo from "assets/img/events/logo/sgp.svg";
import sgpShapeColour from "assets/img/events/shape/colour/sgp.svg";
import sgpShapeDark from "assets/img/events/shape/dark/sgp.svg";
import perMobile from "assets/img/events/background/mobile/per.svg";
import perDesktop from "assets/img/events/background/desktop/per.svg";
import perLeaderboard from "assets/img/events/leaderboard/mobile/per.svg";
import perLandingMobile from "assets/img/events/landing/mobile/per.svg";
import perLandingDesktop from "assets/img/events/landing/desktop/per.svg";
import perLogo from "assets/img/events/logo/per.svg";
import perShapeColour from "assets/img/events/shape/colour/per.svg";
import perShapeDark from "assets/img/events/shape/dark/per.svg";
import dxbMobile from "assets/img/events/background/mobile/dxb.svg";
import dxbDesktop from "assets/img/events/background/desktop/dxb.svg";
import dxbLeaderboard from "assets/img/events/leaderboard/mobile/dxb.svg";
import dxbLandingMobile from "assets/img/events/landing/mobile/dxb.svg";
import dxbLandingDesktop from "assets/img/events/landing/desktop/dxb.svg";
// import dxbLogo from "assets/img/events/logo/dxb.svg"; TODO: Add missing logo
import vanMobile from "assets/img/events/background/mobile/van.svg";
import vanDesktop from "assets/img/events/background/desktop/van.svg";
import vanLeaderboard from "assets/img/events/leaderboard/mobile/van.svg";
import vanLandingMobile from "assets/img/events/landing/mobile/van.svg";
import vanLandingDesktop from "assets/img/events/landing/desktop/van.svg";
import vanLogo from "assets/img/events/logo/van.svg";
import vanShapeColour from "assets/img/events/shape/colour/van.svg";
import vanShapeDark from "assets/img/events/shape/dark/van.svg";
import hkgMobile from "assets/img/events/background/mobile/hkg.svg";
import hkgDesktop from "assets/img/events/background/desktop/hkg.svg";
import hkgLeaderboard from "assets/img/events/leaderboard/mobile/hkg.svg";
import hkgLandingMobile from "assets/img/events/landing/mobile/hkg.svg";
import hkgLandingDesktop from "assets/img/events/landing/desktop/hkg.svg";
import hkgLogo from "assets/img/events/logo/hkg.svg";
import hkgShapeColour from "assets/img/events/shape/colour/hkg.svg";
import hkgShapeDark from "assets/img/events/shape/dark/hkg.svg";
import globalBackground from "assets/img/commonBackground.svg";
import globalLeaderboard from "assets/img/leaderboardBackground.svg";
import {PaletteColor} from "@mui/material";

const contrastText = "var(--masterbrand-dark, #19000A)";

export interface IEventTheme {
	palette: {
		eventPrimary: PaletteColor;
		eventSecondary: PaletteColor;
	};
	images: {
		background: {
			desktop: string;
			mobile: string;
		};
		leaderboard: {
			desktop: string;
			mobile: string;
		};
		landing: {
			desktop: string;
			mobile: string;
		};
		logo: string;
		shape: {
			colour: string;
			dark: string;
		};
	};
}

const DXB: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#FA0A37",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#FF50FF",
				contrastText,
			},
		}),
	},
	images: {
		background: {
			desktop: dxbDesktop,
			mobile: dxbMobile,
		},
		leaderboard: {
			desktop: dxbLeaderboard,
			mobile: dxbLeaderboard,
		},
		landing: {
			desktop: dxbLandingDesktop,
			mobile: dxbLandingMobile,
		},
		logo: "",
		shape: {
			colour: "",
			dark: "",
		},
	},
};

const CPT: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#00F55A",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#00ADFF",
				contrastText,
			},
		}),
	},
	images: {
		background: {
			desktop: cptDesktop,
			mobile: cptMobile,
		},
		leaderboard: {
			desktop: cptLeaderboard,
			mobile: cptLeaderboard,
		},
		landing: {
			desktop: cptLandingDesktop,
			mobile: cptLandingMobile,
		},
		logo: cptLogo,
		shape: {
			colour: cptShapeColour,
			dark: cptShapeDark,
		},
	},
};

const PER: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#F5F505",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#4DD9FF",
				contrastText,
			},
		}),
	},
	images: {
		background: {
			desktop: perDesktop,
			mobile: perMobile,
		},
		leaderboard: {
			desktop: perLeaderboard,
			mobile: perLeaderboard,
		},
		landing: {
			desktop: perLandingDesktop,
			mobile: perLandingMobile,
		},
		logo: perLogo,
		shape: {
			colour: perShapeColour,
			dark: perShapeDark,
		},
	},
};

const VAN: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#87D0F1",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#F18623",
				contrastText,
			},
		}),
	},
	images: {
		background: {
			desktop: vanDesktop,
			mobile: vanMobile,
		},
		leaderboard: {
			desktop: vanLeaderboard,
			mobile: vanLeaderboard,
		},
		landing: {
			desktop: vanLandingDesktop,
			mobile: vanLandingMobile,
		},
		logo: vanLogo,
		shape: {
			colour: vanShapeColour,
			dark: vanShapeDark,
		},
	},
};

const LAX: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#3CFADC",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#7881FF",
				contrastText,
			},
		}),
	},
	images: {
		background: {
			desktop: laxDesktop,
			mobile: laxMobile,
		},
		leaderboard: {
			desktop: laxLeaderboard,
			mobile: laxLeaderboard,
		},
		landing: {
			desktop: laxLandingDesktop,
			mobile: laxLandingMobile,
		},
		logo: laxLogo,
		shape: {
			colour: laxShapeColour,
			dark: laxShapeDark,
		},
	},
};

const HKG: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#F50081",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#8D34FF",
				contrastText,
			},
		}),
	},
	images: {
		background: {
			desktop: hkgDesktop,
			mobile: hkgMobile,
		},
		leaderboard: {
			desktop: hkgLeaderboard,
			mobile: hkgLeaderboard,
		},
		landing: {
			desktop: hkgLandingDesktop,
			mobile: hkgLandingMobile,
		},
		logo: hkgLogo,
		shape: {
			colour: hkgShapeColour,
			dark: hkgShapeDark,
		},
	},
};

const SGP: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#DA40FF",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#C6FF7D",
				contrastText,
			},
		}),
	},
	images: {
		background: {
			desktop: sgpDesktop,
			mobile: sgpMobile,
		},
		leaderboard: {
			desktop: sgpLeaderboard,
			mobile: sgpLeaderboard,
		},
		landing: {
			desktop: sgpLandingDesktop,
			mobile: sgpLandingMobile,
		},
		logo: sgpLogo,
		shape: {
			colour: sgpShapeColour,
			dark: sgpShapeDark,
		},
	},
};

const MAD: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#FF5000",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#C90051",
				contrastText,
			},
		}),
	},
	images: {
		background: {
			desktop: madDesktop,
			mobile: madMobile,
		},
		leaderboard: {
			desktop: madLeaderboard,
			mobile: madLeaderboard,
		},
		landing: {
			desktop: madLandingDesktop,
			mobile: madLandingMobile,
		},
		logo: madLogo,
		shape: {
			colour: madShapeColour,
			dark: madShapeDark,
		},
	},
};

export const GLOBAL_THEME: IEventTheme = {
	palette: {
		eventPrimary: theme.palette.augmentColor({
			color: {
				main: "#E6FFF2",
				contrastText,
			},
		}),
		eventSecondary: theme.palette.augmentColor({
			color: {
				main: "#19000A",
				contrastText: "#E6FFF2",
			},
		}),
	},
	images: {
		background: {
			desktop: globalBackground,
			mobile: globalBackground,
		},
		leaderboard: {
			desktop: globalLeaderboard,
			mobile: globalLeaderboard,
		},
		landing: {
			desktop: "",
			mobile: "",
		},
		logo: "",
		shape: {
			colour: "",
			dark: "",
		},
	},
};

export const EVENT_THEMES: Record<string, IEventTheme> = {
	// PROD
	DXB,
	CPT,
	PER,
	VAN,
	LAX,
	HKG,
	SGP,
	MAD,
	// UAT
	HAM: CPT,
	SYD: PER,
	TLS: MAD,
};
