import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalReviewGridController} from "views/components/modals/modal_review_grid/modal_review_grid.controller";
import {Box, Button, ButtonBase, Dialog, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {ReactComponent as CloseIcon} from "assets/img/icons/closeRound.svg";
import {PredictorGrid} from "views/components/predictor_grid/predictor_grid.component";
import {useNavigate} from "react-router";
import {useTheme} from "@emotion/react";

const ModalContent = styled.div`
	width: 100%;
	background: ${({theme}) => theme.palette.eventSecondary.main};
	padding: 6px 20px 30px;
	text-align: center;

	@media screen and (max-width: 640px) {
		padding: 6px 6px 30px;
	}

	button {
		max-width: 300px;
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 20px;
`;

export const ModalReviewGrid: React.FC = observer(() => {
	const theme = useTheme();
	const {i18n, isOpen, gridId, event, close, onSaveGrid} =
		useViewController<IModalReviewGridController>(Bindings.ModalReviewGridController, {
			navigate: useNavigate(),
		});

	return (
		<Dialog open={isOpen} scroll="body" PaperProps={{sx: {width: "100%", maxWidth: "670px"}}}>
			<ModalContent>
				<CloseBlock>
					<ButtonBase sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</ButtonBase>
				</CloseBlock>
				<Box component="img" src={theme.images.logo} width="96px" mb="20px" />
				<Typography variant="h2" sx={{typography: {lg: "h1"}, mb: "6px"}}>
					{i18n.t("REVIEW YOUR GRID")}
				</Typography>
				<Typography
					variant="h4"
					textTransform="uppercase"
					sx={{typography: {lg: "h2"}, mb: {xs: "12px", lg: "24px"}}}>
					{i18n.t("FOR {{event}} SEVENS POOL STAGE", {event})}
				</Typography>

				<Box width="100%" mb="18px">
					{gridId && <PredictorGrid gridId={gridId} readonly={true} />}
				</Box>

				<Stack direction="row" justifyContent="center" gap={2}>
					<Button
						fullWidth
						size="large"
						variant="contained"
						onClick={close}
						sx={{flex: 1}}>
						{i18n.t("EDIT GRID")}
					</Button>
					<Button
						fullWidth
						size="large"
						variant="contained"
						color="eventPrimary"
						onClick={onSaveGrid}
						sx={{flex: 1}}>
						{i18n.t("SAVE GRID")}
					</Button>
				</Stack>
			</ModalContent>
		</Dialog>
	);
});
