import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IEvent, IRoundsStore} from "data/stores/rounds/rounds.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface ILandingUpcomingEventsController extends ViewController {
	readonly i18n: ILocalizationStore;
	get scheduledEvents(): IEvent[];
}

@injectable()
export class LandingUpcomingEventsController implements ILandingUpcomingEventsController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) readonly _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get scheduledEvents() {
		return this._roundsStore.scheduledEvents.filter(
			({abbreviation}) => abbreviation !== this._roundsStore.currentRound?.event.abbreviation
		);
	}
}
