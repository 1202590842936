import {retryFailLoad} from "data/utils";
import React, {Fragment, lazy} from "react";
import {Route} from "react-router-dom";

const LandingSecretPage = lazy(
	retryFailLoad(() => import("views/pages/landing_secret/landing_secret.component"))
);

export const SecretGateRoutes = (
	<Fragment>
		<Route path="*" element={<LandingSecretPage />} />
	</Fragment>
);

export default SecretGateRoutes;
