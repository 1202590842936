import React from "react";
import styled from "@emotion/styled";
import {Stack, Typography} from "@mui/material";
import {useViewController} from "data/services/locator";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";
import {ExternalLink} from "views/components/links/links.component";

const Navigation = styled(Stack)`
	gap: 12px;
	text-transform: uppercase;
	max-width: 300px;
	align-items: flex-start;
	width: 100%;

	${breakpointsTheme.up("md")} {
		flex-flow: row;
		max-width: 100%;
		gap: 32px;
	}
`;

const Text = styled(Typography)``;

Text.defaultProps = {
	variant: "body4",
	sx: {
		typography: {
			md: "button",
		},
	},
};

export const FooterNavigation: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	return (
		<Navigation>
			<ExternalLink href="https://www.svns.com/en/terms-and-conditions">
				<Text>{i18n.t("footer.nav.terms", "terms & conditions")}</Text>
			</ExternalLink>
			<ExternalLink href="https://www.svns.com/en/privacy">
				<Text>{i18n.t("footer.nav.privacy", "Privacy Policy")}</Text>
			</ExternalLink>
			<ExternalLink href="https://www.svns.com/en/cookie-policy">
				<Text>{i18n.t("footer.nav.cookie", "cookie Policy")}</Text>
			</ExternalLink>
		</Navigation>
	);
});
