import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";

export interface IPlayer {
	id: number;
	firstName: string;
	lastName: string;
	percentSelected: number;
	position: string;
	squadId: number;
	eliminated: boolean;
	headshot: string;
	totalTries: number;
}

export interface IPlayersStore {
	get getIsLoading(): boolean;
	get list(): IPlayer[];
	getPlayerById(playerId: number): IPlayer | undefined;
	fetchPlayers(): Promise<void>;
}

@injectable()
export class PlayersStore implements IPlayersStore {
	@observable private _isLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IPlayer[] = [];

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	getPlayerById(playerId: number): IPlayer | undefined {
		return this._list.find((player) => player.id === playerId);
	}

	@action
	async fetchPlayers() {
		const {data} = await this._jsonProvider.players();

		runInAction(() => {
			this._list = data;
		});
	}
}
