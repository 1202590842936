/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {PaletteColorOptions, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface CustomPalette {
		eventPrimary: PaletteColorOptions;
		eventSecondary: PaletteColorOptions;
	}

	interface Palette extends CustomPalette {
		eventPrimary: Palette["primary"];
		eventSecondary: Palette["primary"];
	}

	interface PaletteOptions extends CustomPalette {
		eventPrimary?: PaletteOptions["primary"];
		eventSecondary?: PaletteOptions["primary"];
	}
}

export const paletteTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#19000A",
		},
		secondary: {
			main: "#E9F5ED",
		},
		eventPrimary: {
			main: "#00F55A",
			contrastText: "#19000A",
		},
		eventSecondary: {
			main: "#00ADFF",
			contrastText: "#19000A",
		},
		error: {
			main: "#ff0000",
		},
		warning: {
			main: "#fff100",
		},
		info: {
			main: "#00d4dc",
		},
		success: {
			main: "#00F55A",
		},
	},
};
