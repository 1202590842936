export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	INFO,
	CONFIRM,
	REGISTRATION,
	REVIEW_GRID,
	TUTORIAL,
	SHARE_GRID,
	DESKTOP_SHARE,
	LOGIN,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "locked",
	Complete = "completed",
}

export enum GameStatus {
	Scheduled = "scheduled",
	Playing = "active",
	Complete = "completed",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
	Prediction = "prediction",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum Slot {
	Slot1 = "slot1",
	Slot2 = "slot2",
	Slot3 = "slot3",
	Slot4 = "slot4",
	Slot5 = "slot5",
	Slot6 = "slot6",
	Slot7 = "slot7",
}

export enum SlotPoints {
	Slot1 = "slot1Points",
	Slot2 = "slot2Points",
	Slot3 = "slot3Points",
	Slot4 = "slot4Points",
	Slot5 = "slot5Points",
	Slot6 = "slot6Points",
	Slot7 = "slot7Points",
}

export enum Gender {
	Mens = "mens",
	Womens = "womens",
}

export enum Competition {
	Mens = "Men's",
	Womens = "Women's",
}

export enum Stage {
	Pool = "pool",
	Cup = "cup",
}

export enum SortStat {
	TotalTries = "totalTries",
	PercentSelected = "percentSelected",
}
