import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
} from "data/stores/static_content/static_content.store";
import type {IPlayer} from "data/stores/players/players.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IRound} from "data/stores/rounds/rounds.store";
import type {ISquad} from "data/stores/squads/squads.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";
import {Language} from "data/enums";

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;
	rounds(): Promise<AxiosResponse<IRound[]>>;
	players(): Promise<AxiosResponse<IPlayer[]>>;
	squads(): Promise<AxiosResponse<ISquad[]>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;

	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(locale: string): Promise<AxiosResponse<ICategories>>;
	helpSections(locale: string): Promise<AxiosResponse<ISections>>;
	helpArticles(locale: string): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	rounds = () => this._jsonClient.get<IRound[]>("grid_predictor/grids.json");
	players = () => this._jsonClient.get<IPlayer[]>("grid_predictor/players.json");
	squads = () => this._jsonClient.get<ISquad[]>("grid_predictor/squads.json");
	checksums = () => this._jsonClient.get<IChecksums>("checksums.json");
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`grid_predictor/loco/${locale}.json`);

	helpCategories = (locale: Language) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`);
	helpSections = (locale: Language) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`);
	helpArticles = (locale: Language) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`);
}
