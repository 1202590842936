/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {ThemeOptions} from "@mui/material/styles";
import cptDesktop from "assets/img/events/background/desktop/cpt.svg";
import cptMobile from "assets/img/events/background/mobile/cpt.svg";
import cptLeaderboard from "assets/img/events/leaderboard/mobile/cpt.svg";
import cptLogo from "assets/img/events/logo/cpt.svg";

declare module "@mui/material/styles" {
	export interface Theme {
		images: {
			background: {
				desktop: string;
				mobile: string;
			};
			leaderboard: {
				desktop: string;
				mobile: string;
			};
			landing: {
				desktop: string;
				mobile: string;
			};
			logo: string;
			shape: {
				colour: string;
				dark: string;
			};
		};
	}

	// allow configuration using `createTheme`
	export interface ThemeOptions {
		images?: {
			background?: {
				desktop?: string;
				mobile?: string;
			};
			leaderboard?: {
				desktop?: string;
				mobile?: string;
			};
			logo?: string;
			shape?: {
				colour?: string;
				dark?: string;
			};
		};
	}
}

export const imagesTheme: ThemeOptions = {
	images: {
		background: {
			desktop: cptDesktop,
			mobile: cptMobile,
		},
		leaderboard: {
			desktop: cptLeaderboard,
			mobile: cptLeaderboard,
		},
		logo: cptLogo,
	},
};
