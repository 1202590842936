import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {Gender} from "data/enums";

export interface ISquad {
	id: number;
	name: string;
	abbreviation: string;
	gender: Gender;
	flag: string;
	countryCode: string;
}

export interface ISquadsStore {
	get getIsLoading(): boolean;
	get list(): ISquad[];
	getSquadById(squadId: number): ISquad | undefined;
	fetchSquads(): Promise<void>;
}

@injectable()
export class SquadsStore implements ISquadsStore {
	@observable private _isLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: ISquad[] = [];

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	getSquadById(squadId: number): ISquad | undefined {
		return this._list.find((squad) => squad.id === squadId);
	}

	@action
	async fetchSquads() {
		const {data} = await this._jsonProvider.squads();

		runInAction(() => {
			this._list = data;
		});
	}
}
