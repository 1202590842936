import Keycloak from "keycloak-js";
import {SSO_CLIENT_ID, SSO_CLIENT_REALM, SSO_URL} from "data/constants";

const keycloak = new Keycloak({
	url: SSO_URL,
	realm: SSO_CLIENT_REALM,
	clientId: SSO_CLIENT_ID,
});

export default keycloak;
