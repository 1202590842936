import React, {PropsWithChildren} from "react";
import styled from "@emotion/styled";
import {Stack, IconButton as Button} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import {ReactComponent as TikTokIcon} from "assets/img/icons/tiktok.svg";

const Wrapper = styled(Stack)`
	flex-flow: row;
	padding: 20px 0px;
	align-items: center;
	gap: 14px;
`;

const LinkWrapper: React.FC<PropsWithChildren<{href: string}>> = ({href, children}) => {
	return (
		<Button href={href} target={"_blank"} color={"secondary"} rel="noopener noreferrer">
			{children}
		</Button>
	);
};

export const Socials: React.FC = () => {
	return (
		<Wrapper>
			<LinkWrapper href={"https://www.instagram.com/svnsseries/"}>
				<InstagramIcon color={"secondary"} />
			</LinkWrapper>
			<LinkWrapper href={"https://www.tiktok.com/@svnsseries"}>
				<TikTokIcon />
			</LinkWrapper>
			<LinkWrapper href={"https://www.facebook.com/svnsseries"}>
				<FacebookRoundedIcon color={"secondary"} />
			</LinkWrapper>
			<LinkWrapper href={"https://www.youtube.com/worldrugby"}>
				<YouTubeIcon color={"secondary"} />
			</LinkWrapper>
			<LinkWrapper href={"https://www.twitter.com/SVNSSeries/"}>
				<TwitterIcon color={"secondary"} />
			</LinkWrapper>
		</Wrapper>
	);
};
