import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {getTimeRemaining} from "data/utils/helpers/date";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {RoundStatus} from "data/enums";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";

export interface ILockoutTimerController extends ViewController {
	readonly i18n: ILocalizationStore;
	get selectedRound(): IRound | undefined;
	get isScheduled(): boolean;
	get message(): string;
	get timeRemaining(): ReturnType<typeof getTimeRemaining>;
}

@injectable()
export class LockoutTimerController implements ILockoutTimerController {
	@observable private _interval?: ReturnType<typeof setInterval>;
	@observable private _timeRemaining: ReturnType<typeof getTimeRemaining> = null;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	get selectedRound() {
		return this._roundsStore.selectedRound;
	}

	get lockoutDate() {
		if (!this.selectedRound) {
			return null;
		}
		return this._predictionsStore.getCurrentLockoutDate(this.selectedRound.id);
	}

	get isScheduled() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Scheduled;
	}

	get isLocked() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Playing;
	}

	get message() {
		if (this.isScheduled) {
			return this.i18n.t("grid.countdown.scheduled", "GRID LOCKS IN:");
		}
		if (this.isLocked) {
			return this.i18n.t("grid.countdown.locked", "GRID IS LOCKED");
		}
		return this.i18n.t("grid.countdown.complete", "GRID COMPLETE");
	}

	get timeRemaining() {
		return this._timeRemaining;
	}

	init(): void {
		this._interval = setInterval(() => {
			if (this.lockoutDate) {
				this._timeRemaining = getTimeRemaining(this.lockoutDate);
			}
		}, 1000);
	}

	dispose(): void {
		if (this._interval) {
			clearInterval(this._interval);
		}
	}
}
