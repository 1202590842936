import React from "react";
import {ListItem, ListItemButton, ListItemText, List} from "@mui/material";
import {NavLink} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {INavigationController} from "views/components/header/navigation.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {Exist} from "views/components/exist/exist.component";

const DesktopList = styled(List)`
	display: flex;
	flex-flow: row;
	width: 100%;
	color: #fff;
	height: 80px;
	padding: 0;
`;

const ListItemDesktop = styled(ListItem)`
	flex: 0 0 auto;
	width: auto;
	margin-right: 1px;
	height: 100%;
`;

const ListItemButtonDesktop = styled(ListItemButton)`
	height: 100%;
` as unknown as typeof ListItemButton;

export const DesktopNavigation: React.FC = observer(() => {
	const {i18n, isAuthorized} = useViewController<INavigationController>(
		Bindings.NavigationController
	);

	return (
		<DesktopList>
			<ListItemDesktop disablePadding>
				<ListItemButtonDesktop
					LinkComponent={NavLink}
					component={NavLink}
					to={"/predictor"}>
					<ListItemText primary={i18n.t("navigation.option.grid", "My Grid")} />
				</ListItemButtonDesktop>
			</ListItemDesktop>

			<Exist when={isAuthorized}>
				<ListItemDesktop disablePadding>
					<ListItemButtonDesktop
						LinkComponent={NavLink}
						component={NavLink}
						to={"/rankings"}>
						<ListItemText
							primary={i18n.t("navigation.option.leaderboard", "leaderboard")}
						/>
					</ListItemButtonDesktop>
				</ListItemDesktop>
			</Exist>

			<ListItemDesktop disablePadding>
				<ListItemButtonDesktop LinkComponent={NavLink} component={NavLink} to={"/help"}>
					<ListItemText primary={i18n.t("navigation.option.help", "help")} />
				</ListItemButtonDesktop>
			</ListItemDesktop>

			<Exist when={isAuthorized}>
				<ListItemDesktop disablePadding sx={{ml: "auto"}}>
					<ListItemButtonDesktop
						LinkComponent={NavLink}
						component={NavLink}
						to={"/account"}>
						<ListItemText primary={i18n.t("navigation.option.account", "my account")} />
					</ListItemButtonDesktop>
				</ListItemDesktop>
			</Exist>
		</DesktopList>
	);
});
