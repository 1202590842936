import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IEventThemeController} from "views/components/event_theme/event_theme.controller";
import {Theme, ThemeProvider, createTheme} from "@mui/material";
import {PagePreloader} from "views/components/preloader";
import {useLocation} from "react-router-dom";

export const EventTheme: React.FC<PropsWithChildren> = observer(({children}) => {
	const {isLoading, eventTheme} = useViewController<IEventThemeController>(
		Bindings.EventThemeController,
		{
			location: useLocation(),
		}
	);

	if (isLoading) {
		return <PagePreloader />;
	}

	return (
		<ThemeProvider
			theme={(outerTheme: Theme) =>
				createTheme({
					...outerTheme,
					images: eventTheme.images,
					palette: {
						...outerTheme.palette,
						...eventTheme.palette,
					},
				})
			}>
			{children}
		</ThemeProvider>
	);
});
