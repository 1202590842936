import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {useNavigate} from "react-router";
import {GameStatus, Slot} from "data/enums";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {SLOT_POINTS_MAP} from "data/constants";

interface IProps {
	gridId: number;
	slot: Slot;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IPredictorGridButtonController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get prediction(): number | null | undefined;
	get points(): number | "-";
	get country(): ISquad | undefined;
	get isLocked(): boolean;
	get isMatchComplete(): boolean;
	openSelect: () => void;
}

@injectable()
export class PredictorGridButtonController implements IPredictorGridButtonController {
	@observable private _gridId: number | null = null;
	@observable private _slot: Slot = Slot.Slot1;
	@observable private _navigate?: ReturnType<typeof useNavigate>;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get predictions() {
		if (!this._gridId) {
			return {};
		}
		return this._predictionsStore.getPredictionsByGridID(this._gridId);
	}

	get prediction() {
		return this.predictions[this._slot];
	}

	get country() {
		if (!this.prediction) {
			return;
		}
		return this._squadsStore.getSquadById(this.prediction);
	}

	get round() {
		if (!this._gridId) {
			return;
		}
		return this._roundsStore.getRoundById(this._gridId);
	}

	get isLocked() {
		if (!this._gridId) {
			return true;
		}
		return this._predictionsStore.isGridLocked(this._gridId);
	}

	get match() {
		if (!this.round) {
			return;
		}
		return this.round.games.find(
			({awayId, homeId}) => this.country && [awayId, homeId].includes(this.country.id)
		);
	}

	get isMatchComplete() {
		if (!this.match) {
			return false;
		}

		return this.match.status === GameStatus.Complete;
	}

	get points() {
		if (!this._gridId) {
			return "-";
		}
		const points = this._predictionsStore.getPointsByGridID(this._gridId);
		const index = SLOT_POINTS_MAP.get(this._slot);
		if (points && index) {
			return points[index] ?? 0;
		}
		return 0;
	}

	@action openSelect = () => {
		this._predictionsStore.selectedSlot = this._slot;
		this._navigate?.(`/predictor/country/${String(this._gridId)}`);
	};

	init(param: IProps): void {
		this._gridId = param.gridId;
		this._slot = param.slot;
		this._navigate = param.navigate;
	}

	onChange(param: IProps): void {
		this._gridId = param.gridId;
		this._slot = param.slot;
	}
}
