import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalInfoController} from "views/components/modals/modal_info/modal_info.controller";
import {Stack, IconButton, Typography, Dialog, Box, DialogContent} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";
import styled from "@emotion/styled";
import {useTheme} from "@emotion/react";

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	top: 6px;
	right: 6px;
`;

export const ModalInfo: React.FC = observer(() => {
	const theme = useTheme();
	const {isOpen, i18n, modalContent, close} = useViewController<IModalInfoController>(
		Bindings.ModalInfoController
	);

	return (
		<Dialog open={isOpen} onClose={close} maxWidth={"sm"}>
			<DialogContent>
				<Stack alignItems={"center"} sx={{paddingY: "40px"}} gap={"18px"}>
					<CloseBlock>
						<IconButton
							sx={{minWidth: 0}}
							onClick={close}
							color={"info"}
							size={"small"}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>

					<Box component="img" src={theme.images.logo} maxWidth={"100px"} />

					<Stack gap={"8px"}>
						<Typography>
							{i18n.t(modalContent?.title ?? "modal.info.title", modalContent?.title)}
						</Typography>
						<Exist when={modalContent?.message}>
							<Typography>{modalContent?.message}</Typography>
						</Exist>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
});
