import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {Gender, ModalType, Slot} from "data/enums";
import {
	COUNTRY_SLOTS,
	SLOT_COMPETITION_MAP,
	SLOT_GENDER_MAP,
	STAGE_TRANSLATION_MAP,
} from "data/constants";
import {chain, first, some} from "lodash";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IParam {
	gridId: number;
}

export interface IPredictorCountryController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get countries(): ISquad[];
	get allPredictionsMade(): boolean;
	get showPlayMakerButton(): boolean;
	get showReviewGridButton(): boolean;
	get showBackButton(): boolean;
	get showButtons(): boolean;
	get title(): string;
	get question(): string;
	get slotNumber(): number;
	get phase(): string;
	selectCountry: (id: number) => void;
	selectSlot: (slot: Slot) => void;
	previousSlot: () => void;
	nextSlot: () => void;
	isCountryDisabled: (id: number) => boolean;
	isCountrySelected: (id: number) => boolean;
	getCountryClassName: (id: number) => string;
	getSlotClassName: (slot: Slot) => string;
	getPoolName: (id: number) => string;
	getSlotNumberByCountry: (id: number) => number | "";
	openReviewGridModal: () => void;
}

@injectable()
export class PredictorCountryController implements IPredictorCountryController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get countries() {
		if (!this._roundsStore.selectedRound) {
			return [];
		}

		const genders = SLOT_GENDER_MAP.get(this._predictionsStore.selectedSlot) ?? [];
		return this._squadsStore.list.filter(
			({gender, id}) => genders.includes(gender) && this.getIsCountryPlaying(id)
		);
	}

	get allPredictionsMade() {
		if (!this._roundsStore.selectedRoundId) {
			return false;
		}
		return this._predictionsStore.isAllCountryPredictionsMade(
			this._roundsStore.selectedRoundId
		);
	}

	get showPlayMakerButton() {
		if (!this._roundsStore.selectedRoundId) {
			return false;
		}
		return Boolean(
			!this._predictionsStore.hasSavedPrediction(this._roundsStore.selectedRoundId) &&
				this.allPredictionsMade
		);
	}

	get showReviewGridButton() {
		if (!this._roundsStore.selectedRoundId) {
			return false;
		}
		return Boolean(
			this._predictionsStore.hasSavedPrediction(this._roundsStore.selectedRoundId) &&
				this._predictionsStore.hasPredictionChanged(this._roundsStore.selectedRoundId)
		);
	}

	get showBackButton() {
		if (!this._roundsStore.selectedRoundId) {
			return false;
		}
		if (this._predictionsStore.hasSavedPrediction(this._roundsStore.selectedRoundId)) {
			return true;
		}
		return !this.allPredictionsMade;
	}

	get showButtons() {
		return some([this.showPlayMakerButton, this.showReviewGridButton, this.showBackButton]);
	}

	private get gender() {
		const genders = SLOT_GENDER_MAP.get(this._predictionsStore.selectedSlot);
		return first(genders) === Gender.Mens ? "mens" : "womens";
	}

	get title() {
		return this.gender === "mens" ? this.i18n.t("MEN'S PICKS") : this.i18n.t("WOMEN'S PICKS");
	}

	get question() {
		const eventCategory = this._roundsStore.getEventCategoryBySlot(
			this._predictionsStore.selectedSlot
		);
		if (!eventCategory) {
			return "";
		}

		return this.i18n.t(`questions.${this.gender}.${eventCategory.code}`, {phase: this.phase});
	}

	get selectedSlotCountry() {
		return this._predictionsStore.activePredictions[this._predictionsStore.selectedSlot];
	}

	get slotNumber() {
		return COUNTRY_SLOTS.indexOf(this._predictionsStore.selectedSlot) + 1;
	}

	get phase() {
		if (!this._roundsStore.selectedRound) {
			return "";
		}
		const translation = STAGE_TRANSLATION_MAP.get(this._roundsStore.selectedRound.stage) ?? "";
		return this.i18n.t(translation);
	}

	@action selectCountry = (id: number) => {
		this._predictionsStore.updatePredictions(this._predictionsStore.selectedSlot, id);
		this._predictionsStore.nextEmptySlot();
	};

	@action selectSlot = (slot: Slot) => {
		this._predictionsStore.selectedSlot = slot;
	};

	@action previousSlot = () => {
		this._predictionsStore.previousSlot();
	};

	@action nextSlot = () => {
		this._predictionsStore.nextSlot();
	};

	isCountryDisabled = (id: number) => {
		return this._predictionsStore.isCountrySelected(id);
	};

	isCountrySelected = (id: number) => {
		return this.selectedSlotCountry === id;
	};

	getCountryClassName = (id: number) => {
		if (this.isCountrySelected(id)) {
			return "selected";
		}
		return this.isCountryDisabled(id) ? "disabled" : "";
	};

	getSlotClassName = (slot: Slot) => {
		if (this._predictionsStore.selectedSlot === slot) {
			return "active";
		}
		return this._predictionsStore.isSlotSelected(slot) ? "selected" : "";
	};

	private getIsCountryPlaying = (id: number) => {
		if (!this._roundsStore.selectedRound) {
			return false;
		}
		const competitions = SLOT_COMPETITION_MAP.get(this._predictionsStore.selectedSlot) ?? [];
		return chain(this._roundsStore.selectedRound.games)
			.filter(({competition}) => competitions.includes(competition))
			.flatMap(({awayId, homeId}) => [awayId, homeId])
			.includes(id)
			.value();
	};

	getPoolName = (id: number) => {
		if (!this._roundsStore.selectedRound) {
			return "";
		}

		const competitions = SLOT_COMPETITION_MAP.get(this._predictionsStore.selectedSlot) ?? [];
		const game = chain(this._roundsStore.selectedRound.games)
			.filter(({competition}) => competitions.includes(competition))
			.find(({awayId, homeId}) => [awayId, homeId].includes(id))
			.value();

		return game ? game.stageName : "";
	};

	getSlotNumberByCountry = (id: number) => {
		const slot = this._predictionsStore.getSlotByCountryID(id);
		if (!slot) {
			return "";
		}
		return COUNTRY_SLOTS.indexOf(slot) + 1;
	};

	@action openReviewGridModal = () => {
		this._modalsStore.showModal(ModalType.REVIEW_GRID);
	};

	@action init(param: IParam): void {
		this._roundsStore.selectedRoundId = param.gridId;
	}

	@action onChange(param: IParam): void {
		this._roundsStore.selectedRoundId = param.gridId;
	}
}
