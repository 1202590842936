import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPredictionsApiProvider} from "data/providers/api/predictions.api.provider";
import {RoundStatus, Slot, Stage} from "data/enums";
import {COUNTRY_CODE_MAP, COUNTRY_SLOTS} from "data/constants";
import {chain, every, first, isEqual, last, omit} from "lodash";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {getFlagEmoji} from "data/utils/helpers";
import type {IPlayersStore} from "data/stores/players/players.store";
import {DateTime} from "luxon";

export interface IPredictionsPayload {
	createdAt: string;
	gridId: number;
	slot1: number | null;
	slot2: number | null;
	slot3: number | null;
	slot4: number | null;
	slot5: number | null;
	slot6: number | null;
	slot7: number | null;
}

export interface IPredictions extends IPredictionsPayload {
	createdAt: string;
}

export interface IPoints {
	gridId: number;
	gridPoints: number;
	slot1Points: number;
	slot2Points: number;
	slot3Points: number;
	slot4Points: number;
	slot5Points: number;
	slot6Points: number;
	slot7Points: number;
}

export type TPreditions = Partial<IPredictions>;

export interface IEventPoints {
	eventId: number;
	eventPoints: number;
}

export interface ISummary {
	eventPoints: IEventPoints[];
	overallRank: number | null;
	overallPoints: number | null;
}

export interface IPredictionsStore {
	get savedPredictions(): TPreditions[];
	get predictions(): TPreditions[];
	set predictions(predictions: TPreditions[]);
	get activePredictions(): TPreditions;
	get points(): IPoints[];
	get selectedSlot(): Slot;
	set selectedSlot(slot: Slot);
	get firstChangedPrediction(): number | undefined;
	get hasAnyPredictionChanged(): boolean;
	get summary(): ISummary;

	nextEmptySlot: () => void;
	previousSlot: () => void;
	nextSlot: () => void;
	updatePredictions: (slot: Slot, value: number | null) => void;
	getPredictionsByGridID: (gridId: number) => TPreditions;
	getPointsByGridID: (gridId: number) => IPoints | undefined;
	getOtherPredictionsByGridID: (gridId: number) => TPreditions;
	getOtherPointsByGridID: (gridId: number) => IPoints | undefined;
	isAllCountryPredictionsMade: (gridId: number) => boolean;
	isPredictionsValid: (gridId: number) => boolean;
	isCountrySelected: (id: number) => boolean;
	isPlayerSelected: (id: number) => boolean;
	isSlotSelected: (slot: Slot) => boolean;
	hasSavedPrediction: (gridId: number) => boolean;
	hasPredictionChanged: (gridId: number) => boolean;
	getSlotByCountryID: (id: number) => Slot | undefined;
	getShareMessage: (eventName: string, predictions: Partial<IPredictions>) => string;
	getCurrentLockoutDate: (gridId: number) => string | undefined;
	isGridLocked: (gridId: number) => boolean;
	setDefaultPredictions: () => void;
	fetchPredictions: (gridId: number) => Promise<void>;
	savePredictions: (gridId: number) => Promise<void>;
	autofill: (gridId: number) => Promise<void>;
	fetchOtherPredictions: (userId: number, gridId: number) => Promise<void>;
	fetchSummary: () => Promise<void>;
}

@injectable()
export class PredictionsStore implements IPredictionsStore {
	@observable private _savedPredictions: TPreditions[] = [];
	@observable private _predictions: TPreditions[] = [];
	@observable private _points: IPoints[] = [];
	@observable private _selectedSlot: Slot = Slot.Slot1;
	@observable private _otherUserPredictions: TPreditions[] = [];
	@observable private _otherUserPoints: IPoints[] = [];
	@observable private _summary: ISummary = {
		eventPoints: [],
		overallRank: null,
		overallPoints: null,
	};

	constructor(
		@inject(Bindings.PredictionsApiProvider)
		private _predictionsApiProvider: IPredictionsApiProvider,
		@inject(Bindings.SquadsStore)
		private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore)
		private _roundsStore: IRoundsStore,
		@inject(Bindings.PlayersStore)
		private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	get savedPredictions() {
		return this._savedPredictions;
	}

	get predictions() {
		return this._predictions;
	}

	set predictions(predictions: TPreditions[]) {
		this._predictions = predictions;
	}

	get activePredictions() {
		if (!this._roundsStore.selectedRoundId) {
			return {};
		}
		return this.getPredictionsByGridID(this._roundsStore.selectedRoundId);
	}

	get points() {
		return this._points;
	}

	get selectedSlot() {
		return this._selectedSlot;
	}

	set selectedSlot(slot: Slot) {
		this._selectedSlot = slot;
	}

	get selectedSlotIndex() {
		return COUNTRY_SLOTS.indexOf(this.selectedSlot);
	}

	get firstChangedPrediction() {
		return this._roundsStore.currentEventRounds.find(({id}) => this.hasPredictionChanged(id))
			?.id;
	}

	get hasAnyPredictionChanged() {
		return Boolean(
			this._roundsStore.currentEventRounds.find(({id}) => this.hasPredictionChanged(id))
		);
	}

	get otherUserPredictions() {
		return this._otherUserPredictions;
	}

	get otherUserPoints() {
		return this._otherUserPoints;
	}

	get summary() {
		return this._summary;
	}

	@action nextEmptySlot = () => {
		const firstEmptySlot = COUNTRY_SLOTS.find((slot) => !this.activePredictions[slot]);
		const nextEmptySlot = COUNTRY_SLOTS.find(
			(slot, index) => !this.activePredictions[slot] && index > this.selectedSlotIndex
		);

		const emptySlot = nextEmptySlot || firstEmptySlot;
		if (emptySlot) {
			this.selectedSlot = emptySlot;
		} else {
			this.nextSlot();
		}
	};

	@action previousSlot = () => {
		const newSlotIndex = this.selectedSlotIndex - 1;
		if (newSlotIndex >= 0) {
			this.selectedSlot = COUNTRY_SLOTS[newSlotIndex];
		}
	};

	@action nextSlot = () => {
		const newSlotIndex = this.selectedSlotIndex + 1;
		if (newSlotIndex < COUNTRY_SLOTS.length) {
			this.selectedSlot = COUNTRY_SLOTS[newSlotIndex];
		}
	};

	@action updatePredictions = (slot: Slot, value: number | null) => {
		if (!this._roundsStore.selectedRoundId) {
			return;
		}

		this._predictions = this.predictions.map((predictions) =>
			predictions.gridId === this._roundsStore.selectedRoundId
				? {
						...predictions,
						[slot]: value,
				  }
				: predictions
		);
	};

	getSavedPredictionsByGridID = (gridId: number) => {
		return this.savedPredictions.find((prediction) => prediction.gridId === gridId) ?? {};
	};

	getPredictionsByGridID = (gridId: number) => {
		return this.predictions.find((prediction) => prediction.gridId === gridId) ?? {};
	};

	getPointsByGridID = (gridId: number) => {
		return this.points.find((points) => points.gridId === gridId);
	};

	isAllCountryPredictionsMade = (gridId: number) => {
		const predictions = this.getPredictionsByGridID(gridId);
		return chain(COUNTRY_SLOTS)
			.filter((slot) => !predictions[slot])
			.isEmpty()
			.value();
	};

	isPredictionsValid = (gridId: number) => {
		const predictions = this.getPredictionsByGridID(gridId);
		return every([
			predictions.gridId,
			predictions.slot7,
			this.isAllCountryPredictionsMade(gridId),
		]);
	};

	isCountrySelected = (id: number) => {
		return chain(this.activePredictions)
			.omit(["gridId", "slot7"])
			.values()
			.compact()
			.includes(id)
			.value();
	};

	isPlayerSelected = (id: number) => {
		return this.activePredictions.slot7 === id;
	};

	isSlotSelected = (slot: Slot) => {
		return Boolean(this.activePredictions[slot]);
	};

	hasSavedPrediction = (gridId: number) => {
		return chain(this._savedPredictions)
			.map(({gridId}) => gridId)
			.includes(gridId)
			.value();
	};

	hasPredictionChanged = (gridId: number) => {
		const savedPrediction = omit(this.getSavedPredictionsByGridID(gridId), "gridId");
		const currentPrediction = omit(this.getPredictionsByGridID(gridId), "gridId");
		return !isEqual(savedPrediction, currentPrediction);
	};

	getSlotByCountryID = (id: number) => {
		const squad = this._squadsStore.getSquadById(id);
		if (!squad) {
			return;
		}

		return chain(this.activePredictions)
			.omit(["createdAt", "gridId", "slot7"])
			.findKey((countryID) => {
				if (countryID) {
					const sq = this._squadsStore.getSquadById(countryID);
					return sq?.abbreviation === squad.abbreviation;
				}
			})
			.value() as Slot;
	};

	getOtherPredictionsByGridID = (gridId: number) => {
		return this.otherUserPredictions.find((prediction) => prediction.gridId === gridId) ?? {};
	};

	getOtherPointsByGridID = (gridId: number) => {
		return this.otherUserPoints.find((points) => points.gridId === gridId);
	};

	private getFlagEmojiBySquadID = (squadId: number) => {
		const squad = this._squadsStore.getSquadById(squadId);
		if (!squad) {
			return null;
		}

		const code = COUNTRY_CODE_MAP.get(squad.countryCode);

		if (!code) {
			return null;
		}

		return getFlagEmoji(code);
	};

	getShareMessage(eventName: string, predictions: Partial<IPredictions>) {
		if (!predictions.slot7) {
			return "";
		}

		const predictionsLine1 = chain(COUNTRY_SLOTS)
			.slice(0, 3)
			.map((slot) => predictions[slot])
			.compact()
			.map(this.getFlagEmojiBySquadID)
			.compact()
			.join("")
			.value();

		const predictionsLine2 = chain(COUNTRY_SLOTS)
			.slice(3, 6)
			.map((slot) => predictions[slot])
			.compact()
			.map(this.getFlagEmojiBySquadID)
			.compact()
			.join("")
			.value();

		const player = this._playersStore.getPlayerById(predictions.slot7);

		if (!player) {
			return "";
		}

		return `Here is my HSBC SVNS Predictor Grid for ${eventName}:\n\n${predictionsLine1}\n${predictionsLine2}\n⭐${player.firstName} ${player.lastName}\n`;
	}

	getCurrentLockoutDate = (gridId: number) => {
		const round = this._roundsStore.getRoundById(gridId);
		if (!round) {
			return;
		}

		if (round.stage === Stage.Cup) {
			return first(round.lockoutDates);
		}

		const savedPrediction = this.getSavedPredictionsByGridID(gridId);
		if (!savedPrediction.createdAt) {
			return last(round.lockoutDates);
		}

		const createdAt = DateTime.fromISO(savedPrediction.createdAt);
		return (
			round.lockoutDates.find((lockoutDate) => createdAt < DateTime.fromISO(lockoutDate)) ??
			last(round.lockoutDates)
		);
	};

	isGridLocked = (gridId: number) => {
		const lockoutDate = this.getCurrentLockoutDate(gridId);
		if (!lockoutDate) {
			return false;
		}

		return DateTime.now() > DateTime.fromISO(lockoutDate);
	};

	@action setDefaultPredictions = () => {
		// Set prediction object with grid ID if no prediction exists
		this._roundsStore.currentEventRounds.forEach((round) => {
			const roundPrediction = this.getPredictionsByGridID(round.id);
			if (!roundPrediction.gridId) {
				const defaultPrediction = {...roundPrediction, gridId: round.id};
				this._predictions = [...this._predictions, defaultPrediction];
			}
		});
	};

	@action
	async fetchPredictions(eventId: number) {
		const {data} = await this._predictionsApiProvider.event({eventId});

		runInAction(() => {
			this._savedPredictions = data.success.predictions;
			this._predictions = data.success.predictions;
			this._points = data.success.points;

			this.setDefaultPredictions();

			// Move to next grid if locked and user did not save predictions
			// Should only effect mobile UI
			if (!this._roundsStore.selectedRound) {
				return;
			}
			const {id, status} = this._roundsStore.selectedRound;
			const isRoundLocked = status === RoundStatus.Playing;
			const hasPredictions = this.hasSavedPrediction(id);
			const nextRound = this._roundsStore.currentEventRounds.find(
				({status}) => status === RoundStatus.Scheduled
			);
			if (isRoundLocked && !hasPredictions && nextRound) {
				this._roundsStore.selectedRoundId = nextRound.id;
			}
		});
	}

	@action
	async savePredictions(gridId: number) {
		const predictions = this.getPredictionsByGridID(gridId);
		if (!this.isPredictionsValid(gridId)) {
			return;
		}

		const predictionsPayload = omit(predictions, "createdAt") as IPredictionsPayload;
		const {data} = await this._predictionsApiProvider.save(predictionsPayload);

		runInAction(() => {
			this._savedPredictions = this._savedPredictions
				.filter((prediction) => prediction.gridId !== gridId)
				.concat(data.success.prediction);
			this._predictions = this._predictions
				.filter((prediction) => prediction.gridId !== gridId)
				.concat(data.success.prediction);
		});
	}

	@action
	async autofill(gridId: number) {
		const predictions = this.getPredictionsByGridID(gridId);
		if (!predictions.gridId) {
			return;
		}

		const predictionsPayload = omit(predictions, "createdAt") as IPredictionsPayload;
		const {data} = await this._predictionsApiProvider.autofill(predictionsPayload);

		runInAction(() => {
			this._predictions = this._predictions
				.filter((prediction) => prediction.gridId !== gridId)
				.concat(data.success.prediction);
		});
	}

	@action
	async fetchOtherPredictions(userId: number, eventId: number) {
		const {data} = await this._predictionsApiProvider.otherPredictions({userId, eventId});

		runInAction(() => {
			this._otherUserPredictions = data.success.predictions;
			this._otherUserPoints = data.success.points;
		});
	}

	@action
	async fetchSummary() {
		const {data} = await this._predictionsApiProvider.summary();

		runInAction(() => {
			this._summary = data.success;
		});
	}
}
