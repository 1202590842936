import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {share} from "data/utils";
import {ModalType, ShareType, SocialNetwork} from "data/enums";

export interface IModalDesktopShareController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	close: () => void;
	shareFacebook: () => void;
	shareTwitter: () => void;
}

@injectable()
export class ModalDesktopShareController implements IModalDesktopShareController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.DESKTOP_SHARE;
	}

	get gridId() {
		return this._modalsStore.modalContent?.gridId;
	}

	get message() {
		return this._modalsStore.modalContent?.message;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	shareFacebook = () => {
		if (!this.gridId || !this._userStore.user) {
			return;
		}

		share({
			socialNetwork: SocialNetwork.Facebook,
			type: ShareType.Prediction,
			lang: this.i18n.lang,
			gridId: this.gridId,
			userId: this._userStore.user.id,
			message: this.message,
		});
	};

	shareTwitter = () => {
		if (!this.gridId || !this._userStore.user) {
			return;
		}

		share({
			socialNetwork: SocialNetwork.Twitter,
			type: ShareType.Prediction,
			lang: this.i18n.lang,
			gridId: this.gridId,
			userId: this._userStore.user.id,
			message: this.message,
		});
	};
}
