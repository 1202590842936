import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import {AxiosResponse} from "axios";
import {IEventRankings, IRankings} from "data/stores/rankings/rankings.store";

type IOverallResponse = IApiResponse<{leaderboard: IRankings}>;
type IEventResponse = IApiResponse<{leaderboard: IEventRankings}>;

export interface IOverallParams {
	limit?: number;
	page?: number;
}

export interface IEventPrams {
	eventId?: number;
	limit?: number;
	page?: number;
}

export interface IRankingsProvider {
	overall: (params: IOverallParams) => Promise<AxiosResponse<IOverallResponse>>;
	event: (params: IEventPrams) => Promise<AxiosResponse<IEventResponse>>;
}

@injectable()
export class RankingsProvider implements IRankingsProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	overall = (params: IOverallParams) =>
		this._http.get<IOverallResponse>(`grid_predictor/leaderboards/overall`, params);
	event = (params: IOverallParams) =>
		this._http.get<IEventResponse>(`grid_predictor/leaderboards/event`, params);
}
