import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IPredictorGridController} from "views/components/predictor_grid/predictor_grid.controller";
import styled from "@emotion/styled";
import {PredictorGridButton} from "views/components/predictor_grid_button/predictor_grid_button.component";
import {Button, Typography} from "@mui/material";
import {Slot} from "data/enums";
import {Link} from "react-router-dom";
import {PredictorGridPlayerButton} from "views/components/predictor_grid_player_button/predictor_grid_player_button.component";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as ShareIcon} from "assets/img/icons/share.svg";

const Wrapper = styled.div`
	flex: 1;
`;

const Grid = styled.div<{readonly: boolean; hasTitle: boolean}>`
	min-height: ${({readonly}) => !readonly && "420px"};
	padding-bottom: ${({readonly}) => (readonly ? "24px" : "12px")};
	padding-top: 12px;
	padding-right: 26px;
	border-radius: 22px;
	background-color: var(--masterbrand-dark, #19000a);
	color: #fff;
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media screen and (min-width: 900px) {
		min-height: ${({readonly}) => !readonly && "544px"};
		border-top-left-radius: ${({readonly, hasTitle}) => !readonly && hasTitle && "0"};
		gap: 16px;
	}

	@media screen and (min-width: 1100px) {
		min-height: ${({readonly}) => !readonly && "600px"};
		padding-bottom: ${({readonly}) => (readonly ? "30px" : "10px")};
		padding-right: 32px;
	}

	@media screen and (min-width: 1440px) {
		min-height: ${({readonly}) => !readonly && "684px"};
		padding-bottom: ${({readonly}) => (readonly ? "30px" : "18px")};
		padding-right: 42px;
	}
`;

const GridInner = styled.div`
	display: flex;
	align-items: stretch;
	gap: 12px;

	@media screen and (min-width: 900px) {
		gap: 16px;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	@media screen and (min-width: 900px) {
		gap: 16px;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	@media screen and (min-width: 900px) {
		gap: 16px;
	}
`;

const GridColumn = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;

const GridColumnSmall = styled.div`
	width: 32px;

	@media screen and (min-width: 900px) {
		width: 60px;
	}
`;

const GridLabel = styled.div`
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;

	@media screen and (min-width: 900px) {
		height: 60px;
		font-size: 16px;
	}
`;

const GridLabelX = styled(GridLabel)`
	flex: 1;
`;

const GridLabelY = styled(GridLabel)`
	transform: rotateZ(270deg);
	height: 90px;

	@media screen and (min-width: 900px) {
		height: 116px;
	}

	@media screen and (min-width: 1100px) {
		height: 135px;
	}

	@media screen and (min-width: 1440px) {
		height: 160px;
	}
`;

const Predictions = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;

	@media screen and (min-width: 900px) {
		gap: 16px;
	}
`;

const Buttons = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	gap: 10px;
`;

const MessageWrapper = styled.div`
	padding: 10px 12px;
	border-radius: 4px;
	background: ${({theme}) => theme.palette.eventSecondary.main};
`;

const Tab = styled.div`
	min-width: 178px;
	border-radius: 22px 22px 0 0px;
	background: var(--masterbrand-dark, #19000a);
	color: var(--masterbrand-light, #e6fff2);
	padding: 12px;
	display: none;

	@media screen and (min-width: 900px) {
		display: inline-block;
	}
`;

interface IProps {
	gridId: number;
	readonly?: boolean;
}

export const PredictorGrid: React.FC<IProps> = observer(({gridId, readonly = false}) => {
	const {
		i18n,
		isLoading,
		arePredictionsComplete,
		isLocked,
		isComplete,
		points,
		title,
		event,
		hasMultipleRounds,
		isAuthorized,
		autofill,
		resetSlot,
		share,
	} = useViewController<IPredictorGridController>(Bindings.PredictorGridController, {gridId});

	return (
		<Wrapper>
			<Exist when={!readonly && hasMultipleRounds}>
				<Tab>
					<Typography variant="body3" textAlign="center">
						{title}
					</Typography>
				</Tab>
			</Exist>
			<Grid readonly={readonly} hasTitle={hasMultipleRounds}>
				<Row>
					<GridColumnSmall />
					<GridColumn>
						<GridLabelX>
							{event &&
								i18n.t(`grid.column.${event.category1.code}`, event.category1.name)}
						</GridLabelX>
					</GridColumn>
					<GridColumn>
						<GridLabelX>
							{event &&
								i18n.t(`grid.column.${event.category2.code}`, event.category2.name)}
						</GridLabelX>
					</GridColumn>
					<GridColumn>
						<GridLabelX>
							{event &&
								i18n.t(`grid.column.${event.category3.code}`, event.category3.name)}
						</GridLabelX>
					</GridColumn>
				</Row>

				<GridInner>
					<Column>
						<GridColumnSmall>
							<GridLabelY>{i18n.t("WOMENS")}</GridLabelY>
						</GridColumnSmall>
						<GridColumnSmall>
							<GridLabelY>{i18n.t("MENS")}</GridLabelY>
						</GridColumnSmall>
						<GridColumnSmall>
							<GridLabelY>{i18n.t("grid.column.playmaker", "PLAYMAKER")}</GridLabelY>
						</GridColumnSmall>
					</Column>

					<Predictions>
						<Row>
							<GridColumn>
								<PredictorGridButton
									gridId={gridId}
									slot={Slot.Slot1}
									readonly={readonly}
								/>
							</GridColumn>
							<GridColumn>
								<PredictorGridButton
									gridId={gridId}
									slot={Slot.Slot2}
									readonly={readonly}
								/>
							</GridColumn>
							<GridColumn>
								<PredictorGridButton
									gridId={gridId}
									slot={Slot.Slot3}
									readonly={readonly}
								/>
							</GridColumn>
						</Row>
						<Row>
							<GridColumn>
								<PredictorGridButton
									gridId={gridId}
									slot={Slot.Slot4}
									readonly={readonly}
								/>
							</GridColumn>
							<GridColumn>
								<PredictorGridButton
									gridId={gridId}
									slot={Slot.Slot5}
									readonly={readonly}
								/>
							</GridColumn>
							<GridColumn>
								<PredictorGridButton
									gridId={gridId}
									slot={Slot.Slot6}
									readonly={readonly}
								/>
							</GridColumn>
						</Row>
						<Row>
							<GridColumn>
								<PredictorGridPlayerButton gridId={gridId} readonly={readonly} />
							</GridColumn>
						</Row>
					</Predictions>
				</GridInner>

				<Exist when={!readonly}>
					<GridInner>
						<Column>
							<GridColumnSmall />
						</Column>

						<Buttons>
							<Exist when={isLocked}>
								<Exist when={!isComplete}>
									<MessageWrapper>
										<Typography variant="body2" color="#000">
											{i18n.t("grid.locked.button", "THIS GRID IS NOW LIVE!")}
										</Typography>
									</MessageWrapper>
								</Exist>
								<Exist when={isComplete}>
									<MessageWrapper>
										<Typography variant="body2" color="#000">
											{i18n.t(
												"YOU SCORED {{points}} PTS",
												"YOU SCORED {{points}} PTS",
												{points}
											)}
										</Typography>
									</MessageWrapper>
								</Exist>
							</Exist>
							<Exist when={!isLocked}>
								{arePredictionsComplete ? (
									<Fragment>
										<Button
											size="large"
											fullWidth
											onClick={resetSlot}
											variant="contained"
											component={Link}
											to={`/predictor/country/${gridId}`}
											color="eventSecondary"
											sx={{
												maxWidth: "188px",
											}}>
											{i18n.t("EDIT GRID")}
										</Button>
										<Exist when={isAuthorized}>
											<Button
												size="large"
												fullWidth
												onClick={share}
												variant="contained"
												color="eventPrimary"
												sx={{maxWidth: "188px"}}
												disabled={isLoading}
												endIcon={<ShareIcon />}>
												{i18n.t("share.modal.button", "SHARE")}
											</Button>
										</Exist>
									</Fragment>
								) : (
									<Button
										size="large"
										fullWidth
										onClick={autofill}
										variant="contained"
										color="eventPrimary"
										sx={{maxWidth: "188px"}}
										disabled={isLoading}>
										{i18n.t("AUTO PICK")}
									</Button>
								)}
							</Exist>
						</Buttons>
					</GridInner>
				</Exist>
			</Grid>
		</Wrapper>
	);
});
