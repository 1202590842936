import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import {SelectChangeEvent} from "@mui/material";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {chain} from "lodash";
import {SortOrder, SortStat} from "data/enums";
import {SyntheticEvent} from "react";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

interface IFilters {
	gender: string;
	country: string;
}

export interface ISort {
	stat: SortStat;
	order: SortOrder;
}

export interface IPlayerPoolController extends ViewController {
	readonly i18n: ILocalizationStore;
	get players(): IPlayer[];
	get squads(): ISquad[];
	get filters(): IFilters;
	get sort(): ISort;
	onChangeFilters: (e: SelectChangeEvent<unknown>) => void;
	onChangeSort: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

@injectable()
export class PlayerPoolController implements IPlayerPoolController {
	@observable private _filters = {
		gender: "all",
		country: "all",
	};
	@observable private _sort = {
		stat: SortStat.TotalTries,
		order: SortOrder.DESC,
	};

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get players() {
		return chain(this._playersStore.list)
			.filter((player) => this.getIsCountryPlaying(player.squadId))
			.filter((player) => {
				const country = this._squadsStore.getSquadById(player.squadId);

				if (!country) {
					return false;
				}

				if (this.filters.gender !== "all" && this.filters.gender !== country.gender) {
					return false;
				}

				if (
					this.filters.country !== "all" &&
					this.filters.country !== country.abbreviation
				) {
					return false;
				}

				return !player.eliminated;
			})
			.orderBy(this.sort.stat, this.sort.order)
			.value();
	}

	get filters() {
		return this._filters;
	}

	get sort() {
		return this._sort;
	}

	get squads() {
		return chain(this._squadsStore.list)
			.filter(({id}) => this.getIsCountryPlaying(id))
			.uniqBy("abbreviation")
			.sortBy("name")
			.value();
	}

	onChangeFilters = (e: SelectChangeEvent<unknown>) => {
		const {value, name} = e.target;
		this._filters = {
			...this._filters,
			[name]: value,
		};
	};

	onChangeSort = (e: SyntheticEvent<HTMLDivElement, Event>) => {
		const sort = e.currentTarget.dataset.sort as SortStat;
		if (sort === this.sort.stat) {
			const order = this.sort.order === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
			this._sort.order = order;
			return;
		}
		this._sort = {
			stat: sort,
			order: SortOrder.DESC,
		};
	};

	private getIsCountryPlaying = (id: number) => {
		if (!this._roundsStore.selectedRound) {
			return false;
		}
		return chain(this._roundsStore.selectedRound.games)
			.flatMap(({awayId, homeId}) => [awayId, homeId])
			.includes(id)
			.value();
	};
}
