import React from "react";
import {partial} from "lodash";
import styled from "@emotion/styled";
import {ReactComponent as ArrowLeft} from "assets/img/icons/arrowLeft.svg";
import {ReactComponent as ArrowRight} from "assets/img/icons/arrowRight.svg";
import {Stack} from "@mui/material";
import {observer} from "mobx-react";

const Indicators = styled(Stack)`
	flex-flow: row;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

const Indicator = styled.button`
	background: transparent;
	padding: 0;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid #000;
	transition: all 0.2s ease-in-out;

	&.active {
		border: 4px solid #000;
		background: ${({theme}) => theme.palette.eventPrimary.main};
		width: 32px;
		height: 32px;
	}

	&.selected {
		background: var(--masterbrand-dark, #19000a);
	}

	.contained & {
		border: 1px solid var(--masterbrand-light, #e6fff2);
		&.active {
			background: ${({theme}) => theme.palette.eventPrimary.main};
		}

		&.selected {
			background: var(--masterbrand-dark, #19000a);
		}
	}

	.slider & {
		background: var(--masterbrand-light, #e6fff2);
		border: 2px solid var(--masterbrand-light, #e6fff2);

		&.active {
			background: var(--masterbrand-dark, #19000a);
			border: 2px solid var(--masterbrand-light, #e6fff2);
			width: 20px;
			height: 20px;
		}
	}
`;

const Arrow = styled.button`
	background: transparent;
	border: none;
	padding: 0;
	color: inherit;
	border-radius: 50%;
	transition: all 0.2s ease-in-out;

	svg {
		vertical-align: middle;
	}

	&:hover {
		color: #fff;
		background: var(--masterbrand-dark, #19000a);
	}
`;

interface IProps {
	onPrev: () => void;
	onNext: () => void;
	onSelect: (option: string) => void;
	setOptionClassName: (option: string) => string;
	options: string[];
	variant?: "contained" | "outlined" | "slider";
	arrowsColor?: string;
}

export const IndicatorDots: React.FC<IProps> = observer((props) => {
	const {
		onPrev,
		onNext,
		onSelect,
		options,
		setOptionClassName,
		variant = "outlined",
		arrowsColor = "#19000A",
	} = props;

	return (
		<Indicators className={variant} sx={{color: arrowsColor}}>
			<Arrow onClick={onPrev}>
				<ArrowLeft />
			</Arrow>
			{options.map((option) => (
				<Indicator
					key={option}
					className={setOptionClassName(option)}
					onClick={partial(onSelect, option)}
				/>
			))}
			<Arrow onClick={onNext}>
				<ArrowRight />
			</Arrow>
		</Indicators>
	);
});
