import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IPlayer} from "data/stores/players/players.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {Bindings} from "data/constants/bindings";
import {Slot} from "data/enums";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";

interface IProps {
	player: IPlayer;
}

export interface IPlayerPoolRowController extends ViewController<IProps> {
	get className(): string;
	get isSelected(): boolean;
	get country(): ISquad | undefined;
	selectPlayer: () => void;
	removePlayer: () => void;
}

@injectable()
export class PlayerPoolRowController implements IPlayerPoolRowController {
	@observable private _player: IPlayer | null = null;

	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	get className() {
		if (!this._player) {
			return "";
		}
		return this._predictionsStore.isPlayerSelected(this._player.id) ? "selected" : "";
	}

	get isSelected() {
		if (!this._player) {
			return false;
		}
		return this._predictionsStore.isPlayerSelected(this._player.id);
	}

	get country() {
		if (!this._player) {
			return;
		}
		return this._squadsStore.getSquadById(this._player.squadId);
	}

	@action removePlayer = () => {
		this._predictionsStore.updatePredictions(Slot.Slot7, null);
	};

	@action selectPlayer = () => {
		if (!this._player) {
			return;
		}
		this._predictionsStore.updatePredictions(Slot.Slot7, this._player.id);
	};

	init(param: IProps): void {
		this._player = param.player;
	}

	onChange(param: IProps): void {
		this._player = param.player;
	}
}
