import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import Keycloak from "keycloak-js";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {useNavigate} from "react-router-dom";

interface IParams {
	navigate: ReturnType<typeof useNavigate>;
	keycloak: Keycloak;
}

export interface ILogoutController extends ViewController<IParams> {
	readonly i18n: ILocalizationStore;
}

@injectable()
export class LogoutController implements ILogoutController {
	@observable _keycloak: Keycloak | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) readonly _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	async init(param: IParams): Promise<void> {
		this._keycloak = param.keycloak;

		await this.logout();
		param.navigate("/");
	}

	logout = async () => {
		try {
			await this._userStore.logout();
		} catch (e) {
			console.error(e);
		} finally {
			if (this._keycloak) {
				void this._keycloak.logout();
			}
		}
	};
}
