/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions, css} from "@mui/material/styles";

// Update the Button prop options
declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		eventPrimary: true;
		eventSecondary: true;
	}
}

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "var(--fontFamilySecond)",
					fontWeight: "400",
					fontSize: "16px",
					borderRadius: "32px",
					boxShadow: "none",
					":hover": {
						boxShadow: "none",
					},
					":disabled": {
						boxShadow: "none",
						background: "grey",
						color: "rgba(17, 17, 17, 0.4)",
						cursor: "default",
					},
				},
				sizeMedium: {
					padding: "12px 16px",
				},
				sizeLarge: {
					padding: "14px 22px",
				},
				containedPrimary: css`
					background: var(--masterbrand-dark);
					color: var(--masterbrand-light);
					border: 1px solid var(--masterbrand-dark);

					&:hover {
						color: var(--masterbrand-dark);
						background: var(--masterbrand-light);
					}
				`,
				outlinedPrimary: {
					borderColor: "blue",
					color: "blue",
					":hover": {
						borderColor: "blue",
					},
					":disabled": {
						border: "none",
					},
				},
				containedSecondary: css`
					color: var(--masterbrand-dark);
					background: var(--masterbrand-light);
					border: 1px solid var(--masterbrand-dark);

					&:hover {
						background: var(--masterbrand-dark);
						color: var(--masterbrand-light);
					}
				`,
				containedInfo: {
					background: "var(--masterbrand-light)",
					color: "var(--masterbrand-dark)",
					":hover": {
						background: "#FFF",
					},
				},
				containedError: {
					background: "red",
					color: "#FFF",
				},
				containedSuccess: {
					background: "#00F55A",
					color: "#19000A",
				},
			},
			variants: [
				{
					props: {className: "byClassName"},
					style: {
						background: "yellow",
						color: "#000",
						":hover": {
							background: "blue",
						},
					},
				},
			],
		},
		MuiIconButton: {
			styleOverrides: {
				colorInfo: css`
					background: #000;
					color: #fff;

					&:hover {
						background: #000;
						color: #fff;
						opacity: 0.7;
					}
				`,
				sizeSmall: css`
					width: 32px;
					height: 32px;
				`,
			},
		},
		MuiListItemButton: {
			defaultProps: {
				sx: {
					typography: "menu",
				},
			},
			styleOverrides: {
				root: css`
					text-transform: uppercase;
					margin-top: 1px;
					&:hover {
						background: var(--masterbrand-light, #e6fff2);
						color: var(--masterbrand-dark);
					}

					&.active {
						background: var(--masterbrand-light, #e6fff2);
						color: var(--masterbrand-dark);
					}
				`,
			},
		},
	},
};
