import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ModalType} from "data/enums";

export interface IModalPayload {
	title?: string;
	message: string;
	callback?: (payload?: unknown) => void;
	gridId?: number;
}

export type TModalArguments = [type: ModalType] | [type: ModalType, content: IModalPayload];

export interface IModalsStore {
	showModal(...args: TModalArguments): void;
	hideModal(): void;
	get modal(): ModalType | null;
	get modalContent(): IModalPayload | null;
}

@injectable()
export class ModalsStore implements IModalsStore {
	@observable _visibleModal: ModalType | null = null;
	@observable _modalContent: IModalPayload | null = null;

	get modal() {
		return this._visibleModal;
	}

	get modalContent() {
		return this._modalContent;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@action hideModal(): void {
		this._visibleModal = null;
		this._modalContent = null;
	}

	@action showModal(...args: TModalArguments): void {
		const [modalType, content = null] = args;

		this._visibleModal = modalType;
		this._modalContent = content;
	}
}
