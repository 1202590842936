import {createTheme} from "@mui/material/styles";
import {buttonsTheme} from "assets/theming/buttons.theme";
import {typographyTheme} from "assets/theming/typography.theme";
import {paletteTheme} from "assets/theming/palette.theme";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";
import {componentsTheme} from "assets/theming/components.theme";
import {imagesTheme} from "assets/theming/images.theme";

export const emptyTheme = createTheme({});
export const theme = createTheme(
	typographyTheme,
	paletteTheme,
	breakpointsTheme,
	buttonsTheme,
	componentsTheme,
	imagesTheme
);
