import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface IRegistrationPayload {
	token: string;
	terms: true;
	isNotificationsEnabled: boolean;
	supportedCountry?: string;
	username?: string;
}

export interface IUpdateUserPayload {
	isNotificationsEnabled: boolean;
}

export interface IUsername {
	username: string;
}

export type TUserResponse = IApiResponse<{user: IUser}>;

export interface IUserApiProvider {
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TUserResponse>>;
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUserResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	checkUsername: (params: IUsername) => Promise<AxiosResponse>;
	deactivateAccount: () => Promise<AxiosResponse>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	checkUsername = (params: IUsername) => this._http.post<void>("user/check_username", params);

	deactivateAccount = () => this._http.post<void>("user/deactivate_account");

	register = (params: IRegistrationPayload) =>
		this._http.post<TUserResponse>("user/register/sso", params);

	update = (params: IUpdateUserPayload) => this._http.post<TUserResponse>("user/update", params);

	user = () => this._http.get<TUserResponse>("user");
}
