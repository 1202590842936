import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {Stage} from "data/enums";

export interface IDaySelectorController extends ViewController {
	readonly i18n: ILocalizationStore;
	get gridPoolStage(): IRound | undefined;
	get gridKnockoutStage(): IRound | undefined;
	get poolButtonVariant(): "contained" | "text";
	get cupButtonVariant(): "contained" | "text";
	setGridPool: () => void;
	setGridCup: () => void;
}

@injectable()
export class DaySelectorController implements IDaySelectorController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get gridPoolStage() {
		return this._roundsStore.currentEventRounds.find(({stage}) => stage === Stage.Pool);
	}

	get gridKnockoutStage() {
		return this._roundsStore.currentEventRounds.find(({stage}) => stage === Stage.Cup);
	}

	get poolButtonVariant() {
		return this.gridPoolStage?.id === this._roundsStore.selectedRound?.id
			? "contained"
			: "text";
	}

	get cupButtonVariant() {
		return this.gridKnockoutStage?.id === this._roundsStore.selectedRound?.id
			? "contained"
			: "text";
	}

	@action
	setGridPool = () => {
		if (!this.gridPoolStage) {
			return;
		}
		this._roundsStore.selectedRoundId = this.gridPoolStage.id;
	};

	@action
	setGridCup = () => {
		if (!this.gridKnockoutStage) {
			return;
		}
		this._roundsStore.selectedRoundId = this.gridKnockoutStage.id;
	};
}
