import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";
import {Bindings} from "data/constants/bindings";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {GameStatus} from "data/enums";

interface IParam {
	gridId: number;
}

export interface IPredictorGridPlayerButtonController extends ViewController<IParam> {
	get player(): IPlayer | undefined;
	get points(): number | "-";
	get country(): ISquad | undefined;
	get isLocked(): boolean;
	get isMatchComplete(): boolean;
}

@injectable()
export class PredictorGridPlayerButtonController implements IPredictorGridPlayerButtonController {
	@observable private _gridId: number | null = null;

	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get predictions() {
		if (!this._gridId) {
			return {};
		}
		return this._predictionsStore.getPredictionsByGridID(this._gridId);
	}

	get player() {
		if (!this.predictions.slot7) {
			return;
		}
		return this._playersStore.getPlayerById(this.predictions.slot7);
	}

	get country() {
		if (!this.player) {
			return;
		}
		return this._squadsStore.getSquadById(this.player.squadId);
	}

	get round() {
		if (!this._gridId) {
			return;
		}
		return this._roundsStore.getRoundById(this._gridId);
	}

	get isLocked() {
		if (!this._gridId) {
			return true;
		}
		return this._predictionsStore.isGridLocked(this._gridId);
	}

	get match() {
		if (!this.round) {
			return;
		}
		return this.round.games.find(
			({awayId, homeId}) => this.country && [awayId, homeId].includes(this.country.id)
		);
	}

	get isMatchComplete() {
		if (!this.match) {
			return false;
		}

		return this.match.status === GameStatus.Complete;
	}

	get points() {
		if (!this._gridId) {
			return "-";
		}
		const points = this._predictionsStore.getPointsByGridID(this._gridId);
		if (points) {
			return points.slot7Points ?? 0;
		}
		return 0;
	}
	init(param: IParam): void {
		this._gridId = param.gridId;
	}

	onChange(param: IParam): void {
		this._gridId = param.gridId;
	}
}
