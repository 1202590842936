import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IEvent, IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {Bindings} from "data/constants/bindings";
import {EVENT_THEMES, GLOBAL_THEME, IEventTheme} from "data/constants/events";
import {get, last} from "lodash";
import {RoundStatus, Stage} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {useNavigate} from "react-router-dom";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";

interface IParam {
	event: IEvent;
	navigate: ReturnType<typeof useNavigate>;
}

export interface ILandingEventController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get rounds(): IRound[];
	get currentRound(): IRound | undefined;
	get eventTheme(): IEventTheme;
	get isComplete(): boolean;
	get isScheduled(): boolean;
	get isLive(): boolean;
	get isPool(): boolean;
	get isCup(): boolean;
	get poolLockoutDate(): Date | null;
	get cupLockoutDate(): Date | null;
	get eventPoints(): number;
	get showEventPoints(): boolean;
	onSelectEvent: () => void;
}

@injectable()
export class LandingEventController implements ILandingEventController {
	@observable private _event: IEvent | null = null;
	@observable private _navigate?: ReturnType<typeof useNavigate>;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) readonly _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) readonly _predictionsStore: IPredictionsStore
	) {
		makeAutoObservable(this);
	}

	get rounds() {
		return this._roundsStore.list.filter((e) => e.event.id === this._event?.id);
	}

	get currentRound() {
		const activeRound = this.rounds.find(({status}) => status === RoundStatus.Playing);
		const scheduledRound = this.rounds.find(({status}) => status === RoundStatus.Scheduled);
		return scheduledRound || activeRound || last(this.rounds);
	}

	get isComplete() {
		return this.currentRound?.status === RoundStatus.Complete;
	}

	get isScheduled() {
		return this.currentRound?.status === RoundStatus.Scheduled;
	}

	get isLive() {
		return this.currentRound?.status === RoundStatus.Playing;
	}

	get isPool() {
		return this.currentRound?.stage === Stage.Pool;
	}

	get isCup() {
		return this.currentRound?.stage === Stage.Cup;
	}

	get eventTheme() {
		if (!this._event) {
			return GLOBAL_THEME;
		}
		return get(EVENT_THEMES, this._event?.abbreviation, GLOBAL_THEME);
	}

	get poolLockoutDate() {
		const round = this.rounds.find(({stage}) => stage === Stage.Pool);
		if (round) {
			const lockoutDate = this._predictionsStore.getCurrentLockoutDate(round.id);
			return lockoutDate ? new Date(lockoutDate) : null;
		}
		return null;
	}

	get cupLockoutDate() {
		const round = this.rounds.find(({stage}) => stage === Stage.Cup);
		if (round) {
			const lockoutDate = this._predictionsStore.getCurrentLockoutDate(round.id);
			return lockoutDate ? new Date(lockoutDate) : null;
		}
		return null;
	}

	get eventPoints() {
		const eventPoints = this._predictionsStore.summary.eventPoints.find(
			(event) => event.eventId === this._event?.id
		);
		if (!eventPoints) {
			return 0;
		}
		return eventPoints.eventPoints ?? 0;
	}

	get showEventPoints() {
		return Boolean(this.rounds.find(({status}) => status !== RoundStatus.Scheduled));
	}

	init(param: IParam): void {
		this._event = param.event;
		this._navigate = param.navigate;
	}

	onSelectEvent = () => {
		if (this.currentRound) {
			this._roundsStore.selectedRoundId = this.currentRound.id;
			this._navigate?.("/predictor");
		}
	};
}
