import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {
	Drawer,
	Box,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Stack,
	Container,
} from "@mui/material";
import {NavLink} from "react-router-dom";
import {CloseSharp, MenuSharp as MenuSharpIcon} from "@mui/icons-material";
import styled from "@emotion/styled";
import {INavigationController} from "views/components/header/navigation.controller";
import logo from "assets/img/logos/svnsWhite.svg";
import {Exist} from "views/components/exist/exist.component";
import {useLocation} from "react-router";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";

const MobileMenuHeader = styled(Stack)`
	flex-flow: row;
	border-bottom: 1px solid var(--masterbrand-light, #e9f5ed);
	background: var(--masterbrand-dark, #19000a);
	padding: 6px 0;
	justify-content: space-between;
	align-items: center;
`;

const HeaderContainer = styled(Container)`
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${breakpointsTheme.up("md")} {
		gap: 100px;
	}
`;

export const DrawerNavigation: React.FC = observer(() => {
	const location = useLocation();
	const {i18n, toggleDrawer, drawerState, isAuthorized, openTutorial} =
		useViewController<INavigationController>(Bindings.NavigationController, {location});

	return (
		<Fragment>
			<IconButton color={"secondary"} onClick={toggleDrawer}>
				<MenuSharpIcon />
			</IconButton>

			<Drawer
				onClose={toggleDrawer}
				anchor={"left"}
				open={drawerState}
				PaperProps={{
					sx: {
						background: "var(--masterbrand-dark)",
					},
				}}>
				<Box
					sx={{
						background: "var(--masterbrand-dark)",
						color: "var(--masterbrand-light)",
						width: "100vw",
					}}>
					<MobileMenuHeader>
						<HeaderContainer>
							<NavLink to={"/"}>
								<img src={logo} alt="HSBC SVNS" width="62" />
							</NavLink>

							<IconButton color={"secondary"} onClick={toggleDrawer}>
								<CloseSharp />
							</IconButton>
						</HeaderContainer>
					</MobileMenuHeader>

					<List>
						<ListItem disablePadding>
							<ListItemButton
								LinkComponent={NavLink}
								component={NavLink}
								to={"/predictor"}>
								<ListItemText
									primary={i18n.t("navigation.option.grid", "My Grid")}
								/>
							</ListItemButton>
						</ListItem>
						<Exist when={isAuthorized}>
							<ListItem disablePadding>
								<ListItemButton
									LinkComponent={NavLink}
									component={NavLink}
									to={"/rankings"}>
									<ListItemText
										primary={i18n.t(
											"navigation.option.leaderboard",
											"leaderboard"
										)}
									/>
								</ListItemButton>
							</ListItem>
						</Exist>
						<ListItem disablePadding>
							<ListItemButton
								LinkComponent={NavLink}
								component={NavLink}
								to={"/help"}>
								<ListItemText primary={i18n.t("navigation.option.help", "help")} />
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<ListItemButton onClick={openTutorial}>
								<ListItemText
									primary={i18n.t("navigation.option.tutorial", "tutorial")}
								/>
							</ListItemButton>
						</ListItem>
						<Exist when={isAuthorized}>
							<ListItem disablePadding>
								<ListItemButton
									LinkComponent={NavLink}
									component={NavLink}
									to={"/account"}>
									<ListItemText
										primary={i18n.t("navigation.option.account", "my account")}
									/>
								</ListItemButton>
							</ListItem>
						</Exist>
					</List>
				</Box>
			</Drawer>
		</Fragment>
	);
});
