import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IEventRankRow, IRankRow, IRankingsStore} from "data/stores/rankings/rankings.store";
import {defaultTo} from "lodash";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {Stage} from "data/enums";

interface IProps {
	standing: IRankRow | IEventRankRow;
}

export interface IRankingsRowController extends ViewController<IProps> {
	get prevRank(): number | "--";
	get rank(): number | "--";
	get pool(): number | "--";
	get cup(): number | "--";
	get total(): number | "--";
	get isUp(): boolean;
	get isDown(): boolean;
	get canViewGrid(): boolean;
	get showCupPoints(): boolean;
	get showPoolPoints(): boolean;
}

@injectable()
export class RankingsRowController implements IRankingsRowController {
	@observable private _standing: IRankRow | IEventRankRow | null = null;

	get prevRank(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		if ("prevEventRank" in this._standing) {
			return defaultTo(this._standing.prevEventRank, "--");
		}
		return "--";
	}

	get rank(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		if ("overallRank" in this._standing) {
			return defaultTo(this._standing.overallRank, "--");
		}
		return defaultTo(this._standing.eventRank, "--");
	}

	get pool(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		if ("poolStagePoints" in this._standing) {
			return defaultTo(this._standing.poolStagePoints, "--");
		}
		return "--";
	}

	get cup(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		if ("cupStagePoints" in this._standing) {
			return defaultTo(this._standing.cupStagePoints, "--");
		}
		return "--";
	}

	get total(): number | "--" {
		if (!this._standing) {
			return "--";
		}
		if ("overallPoints" in this._standing) {
			return defaultTo(this._standing.overallPoints, "--");
		}
		return defaultTo(this._standing.eventPoints, "--");
	}

	get isUp(): boolean {
		if (!this._standing) {
			return false;
		}
		if (
			"prevEventRank" in this._standing &&
			this._standing.prevEventRank &&
			this._standing.eventRank
		) {
			return this._standing.eventRank < this._standing.prevEventRank;
		}
		return false;
	}

	get isDown(): boolean {
		if (!this._standing) {
			return false;
		}
		if (
			"prevEventRank" in this._standing &&
			this._standing.prevEventRank &&
			this._standing.eventRank
		) {
			return this._standing.eventRank > this._standing.prevEventRank;
		}
		return false;
	}

	get hasScoreRound(): boolean {
		return Boolean(this._roundsStore.scoreRound);
	}

	get canViewGrid(): boolean {
		return this._rankingsStore.filters.event !== "overall" && this.hasScoreRound;
	}

	get showCupPoints(): boolean {
		return (
			this._rankingsStore.filters.event !== "overall" &&
			this._rankingsStore.filters.stage === Stage.Cup
		);
	}

	get showPoolPoints(): boolean {
		return (
			this._rankingsStore.filters.event !== "overall" &&
			this._rankingsStore.filters.stage === Stage.Pool
		);
	}

	constructor(
		@inject(Bindings.RankingsStore) readonly _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps): void {
		this._standing = param.standing;
	}

	onChange(param: IProps): void {
		this._standing = param.standing;
	}
}
