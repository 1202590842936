import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {useLocation} from "react-router";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

interface IParams {
	location: ReturnType<typeof useLocation>;
}

export interface INavigationController extends ViewController<IParams | void> {
	readonly i18n: ILocalizationStore;

	get drawerState(): boolean;
	get isAuthorized(): boolean;

	toggleDrawer: () => void;
	openTutorial: () => void;
}

@injectable()
export class NavigationController implements INavigationController {
	@observable _drawerState: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) readonly _userStore: IUserStore,
		@inject(Bindings.ModalsStore) readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get drawerState(): boolean {
		return this._drawerState;
	}

	toggleDrawer = () => {
		this._drawerState = !this._drawerState;
	};

	onChange(param: IParams) {
		this._drawerState = false;
	}

	openTutorial = () => {
		this._drawerState = false;
		this._modalsStore.showModal(ModalType.TUTORIAL);
	};
}
